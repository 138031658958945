import { t } from '@lingui/macro';
import { URLKeys, noddiAsync } from 'noddi-async';
import { AvailableBookingTimeWindow } from 'noddi-async/src/types';
import { useAuthContext } from 'noddi-provider';
import { BookingTimeWindowPicker, LoadingScreen, NoddiButton } from 'noddi-ui';
import { DateFormats, format } from 'noddi-util';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import HomeContentWrapper from '../../../components/Layouts/HomeContentWrapper';
import EditBookingTimeWindowDialog from './EditBookingDialog';
import NoBookingDataFound from './NoBookingDataFound';

export default function EditBookingTimeWindow() {
  const { currentUserGroupId: userGroupId } = useAuthContext();
  const { id: bookingId } = useParams();

  const navigate = useNavigate();
  const [editDialogIsOpen, setEditDialogIsOpen] = useState(false);
  const [selectedTimeWindowId, setSelectedTimeWindowId] = useState<number | undefined>();
  const [formattedTimeWindow, setFormattedTimeWindow] = useState<string>('');

  const formatTimeWindow = (timeWindow: AvailableBookingTimeWindow) => {
    const from = new Date(timeWindow.startPublic);
    const to = new Date(timeWindow.endPublic);
    const fromDate = format(from, DateFormats.FULL_WEEKDAY_MONTH_AND_YEAR);

    const fromTime = format(from, DateFormats.HOURS);
    const toTime = format(to, DateFormats.HOURS);

    return `${fromTime}-${toTime} ${fromDate}`;
  };

  const bookingIdAsString = bookingId as string;
  const userGroupIdAsNumber = userGroupId as number;

  const {
    isPending: isBookingPending,
    data: bookingData,
    isError
  } = noddiAsync.useGet({
    type: URLKeys.getUserBooking,
    input: { userGroupId: userGroupIdAsNumber, bookingId: bookingIdAsString },
    queryConfig: {
      enabled: !!userGroupId && !!bookingId
    }
  });

  const {
    isPending: isBookingPermissionsPending,
    data: bookingPermissions,
    isError: isBookingPermissionsError
  } = noddiAsync.useGet({
    type: URLKeys.getEditBookingPermissions,
    input: { id: bookingIdAsString },
    queryConfig: {
      enabled: !!bookingId
    }
  });

  const {
    isPending: isTimeWindowsDataPending,
    data: timeWindowsData,
    isError: isTimeWindowsDataError
  } = noddiAsync.useGet({
    type: URLKeys.getUpdateBookingTimeWindowData,
    input: { id: bookingIdAsString },
    queryConfig: {
      enabled: !!bookingId
    }
  });

  useEffect(() => {
    if (timeWindowsData?.bookingTimeWindowId) {
      setSelectedTimeWindowId(timeWindowsData?.bookingTimeWindowId);
    }
  }, [timeWindowsData]);

  if (isBookingPending || isBookingPermissionsPending || isTimeWindowsDataPending) {
    return <LoadingScreen />;
  }
  if (isError || !bookingData || isBookingPermissionsError || isTimeWindowsDataError) {
    return <NoBookingDataFound />;
  }

  const initialFromDate = bookingPermissions.timeWindows.earliestDate
    ? new Date(bookingPermissions.timeWindows.earliestDate)
    : new Date();

  return (
    <HomeContentWrapper title={t`When is it convenient for us to come to you?`}>
      <div className='-mx-4'>
        <NoddiButton
          onClick={() => {
            navigate(-1);
          }}
          variant='ghost'
          startIcon='ArrowLeft'
        >
          {t`Back`}
        </NoddiButton>
      </div>
      <EditBookingTimeWindowDialog
        isOpen={editDialogIsOpen}
        setIsOpen={setEditDialogIsOpen}
        bookingId={bookingIdAsString}
        timeWindowId={selectedTimeWindowId as number}
        formattedTimeWindow={formattedTimeWindow}
      />
      <div className='rounded-lg sm:bg-primary-white sm:p-6'>
        <BookingTimeWindowPicker
          serviceAreaId={timeWindowsData.serviceAreaId}
          salesItemIds={timeWindowsData.salesItemIds}
          initialFromDate={initialFromDate}
          selectedTimeWindowId={selectedTimeWindowId}
          onAvailableSelect={(timeWindow) => {
            setFormattedTimeWindow(formatTimeWindow(timeWindow));
            setSelectedTimeWindowId(timeWindow.id);
            if (
              bookingPermissions.timeWindows.canEdit &&
              selectedTimeWindowId !== undefined &&
              timeWindowsData.bookingTimeWindowId !== timeWindow.id
            ) {
              setEditDialogIsOpen(true);
            }
          }}
        />
      </div>
    </HomeContentWrapper>
  );
}
