import { Trans, t } from '@lingui/macro';

import HomeContentWrapper from '../../../components/Layouts/HomeContentWrapper';

const NoBookingDataFound = () => {
  return (
    <HomeContentWrapper title={t`Your booking`}>
      <p>
        <Trans>No data found for your booking. Please contact us if the problem persist.</Trans>
      </p>
    </HomeContentWrapper>
  );
};

export default NoBookingDataFound;
