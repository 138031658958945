import { Trans, t } from '@lingui/macro';
import { WheelStorageOptionType, wheelStorageOption } from 'noddi-async/src/types';
import { AvailableAddressPicker, NoddiButton, NoddiIcon, colors } from 'noddi-ui';
import { cn } from 'noddi-ui/src/helpers/utilts';
import { formatCurrencyAmount } from 'noddi-util';

import { useBookingContext } from '../../../../contexts/BookingContext';
import { useWheelStore } from '../../../../stores/WheelStore';

type WheelStorageOptionProps = {
  name: string;
  price: number;
  description?: string;
  handleClick: () => void;
  selectedStorageType: WheelStorageOptionType | undefined;
  currentStorageType: WheelStorageOptionType | undefined;
  licensePlateNumber: string;
};

const WheelStorageOption = ({
  name,
  description,
  price,
  handleClick,
  selectedStorageType,
  currentStorageType,
  licensePlateNumber
}: WheelStorageOptionProps) => {
  const isSelected = selectedStorageType === currentStorageType;
  const { addSelectedWheelStorageOptions } = useWheelStore();
  const { bookingInputData } = useBookingContext();
  const address = bookingInputData?.address;
  const isFree = price === 0;
  const priceFormatted = isFree ? t`Free` : formatCurrencyAmount(price);
  return (
    <div
      className={cn(
        'relative rounded-lg py-4 sm:py-6 px-4 sm:p-8',
        isSelected ? 'bg-primary-purple' : 'bg-primary-white'
      )}
    >
      <div className={cn('flex flex-col gap-2 w-full', isSelected ? 'text-primary-white' : 'text-primary-black')}>
        <p className='text-5'>{name}</p>
        <p className='mt-2 text-4 opacity-70'>{description}</p>
        {currentStorageType === wheelStorageOption.customer && (
          <p className='mt-2 text-4 '>
            <div className='relative -left-1.5 flex items-center'>
              <NoddiIcon name='LocationPin' color={isSelected ? colors.primary.white : colors.primary.black} />
              {address?.name ?? address?.fullName} {address?.streetNumber}
            </div>
          </p>
        )}
        {isSelected && selectedStorageType && selectedStorageType !== wheelStorageOption.customer && (
          <div className='mt-2 max-w-100'>
            <AvailableAddressPicker
              clearAddress={() => {
                addSelectedWheelStorageOptions({
                  licensePlateNumber,
                  pickUpAddress: undefined
                });
              }}
              onSelectAddress={(address) => {
                if (address) {
                  addSelectedWheelStorageOptions({
                    licensePlateNumber,
                    pickUpAddress: address
                  });
                }
              }}
              addresses={[]}
            />
          </div>
        )}
        <div className='flex items-center justify-between'>
          <p className={cn('text-4 font-semibold', isFree && !isSelected ? 'text-signal-success' : '')}>
            {priceFormatted}
          </p>
          <div className='flex w-full justify-end' onClick={handleClick}>
            <NoddiButton
              variant='secondary'
              startIcon={isSelected ? 'Minus' : 'Plus'}
              size='small'
              onClick={handleClick}
            >
              {isSelected ? <Trans>Remove</Trans> : <Trans>Choose</Trans>}
            </NoddiButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export { WheelStorageOption };
