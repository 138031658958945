import { Trans, t } from '@lingui/macro';
import { useAuthContext } from 'noddi-provider';
import { NoddiButton } from 'noddi-ui';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import routes from '../../../../../appRoutes';
import tracking from '../../../../../tracking';

const AlreadyHasTireHotel = () => {
  const { isLoggedIn } = useAuthContext();
  const navigate = useNavigate();

  useEffect(() => {
    tracking.track('alreadyHasTireHotel');
  }, []);

  function handleGoToMyAgreements() {
    if (isLoggedIn) {
      navigate(routes.tireStorage.getPath());
    } else {
      navigate(`${routes.login.getPath()}?redirectUrl=${routes.tireStorage.getPath()}`);
    }
  }

  const description = isLoggedIn
    ? `${t`Click on the button below to see your tire hotel agreements`} 👇`
    : `${t`Please log in and manage your tire hotel subscriptions`}  👇`;

  return (
    <div className='flex min-h-[40vh] flex-col items-center justify-center'>
      <p className='text-6 font-semibold'>
        <Trans>We already have your tires!</Trans>
      </p>
      <p className='text-center'>{description}</p>
      <div className='mt-4'>
        <NoddiButton fullWidth onClick={handleGoToMyAgreements}>
          {isLoggedIn ? <Trans>Go to my agreements</Trans> : <Trans>Log in and go to my agreements</Trans>}
        </NoddiButton>
      </div>
    </div>
  );
};

export default AlreadyHasTireHotel;
