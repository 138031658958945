import { Trans } from '@lingui/macro';
import { Card, CardContent, Typography } from '@mui/material';

import { KeyValueRow, NoddiButton } from '../../../Elements';
import { WheelHeader } from '../WheelMeasurementHeader';
import { WheelSetStatus, WheelSetStatusType } from '../types';

type Props = {
  onClick?: () => void;
  statusSummerWheelSet: WheelSetStatusType;
  statusWinterWheelSet: WheelSetStatusType;
};
const TireDetails = ({ onClick, statusSummerWheelSet, statusWinterWheelSet }: Props) => {
  const hasMeasurements =
    statusSummerWheelSet !== WheelSetStatus.MissingMeasurements ||
    statusWinterWheelSet !== WheelSetStatus.MissingMeasurements;
  return (
    <div>
      <Typography variant='h6'>
        <Trans>Tires</Trans>
      </Typography>
      <Card sx={{ marginTop: '1rem', height: '100%' }}>
        <CardContent>
          <div className='flex h-full flex-col justify-between gap-4'>
            <div>
              <KeyValueRow header={<WheelHeader type='summer' wheelSetStatus={statusSummerWheelSet} />} />
              <KeyValueRow
                header={<WheelHeader type='winter' wheelSetStatus={statusWinterWheelSet} />}
                showDivider={false}
              />
            </div>
            <div className='flex justify-end'>
              {hasMeasurements && (
                <NoddiButton onClick={onClick}>
                  <Trans>See measurements</Trans>
                </NoddiButton>
              )}
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export { TireDetails };
