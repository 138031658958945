import { Trans, t } from '@lingui/macro';
import { Avatar, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { URLKeys, noddiAsync } from 'noddi-async';
import { useAuthContext } from 'noddi-provider';
import { ErrorPage, LoadingScreen } from 'noddi-ui';

import HomeContentWrapper from '../../components/Layouts/HomeContentWrapper';
import MembershipDiscount from './MembershipDiscount';

const Membership = () => {
  const { currentUserGroupId: userGroupId } = useAuthContext();
  if (!userGroupId) {
    return <ErrorPage />;
  }
  const { isPending, data: membershipPrograms } = noddiAsync.useGet({
    type: URLKeys.getMembershipPrograms,
    input: { userGroupId },
    queryConfig: {
      enabled: !!userGroupId
    }
  });

  if (isPending) {
    return <LoadingScreen />;
  }

  return (
    <HomeContentWrapper
      title={t`Membership benefits`}
      subtitle={t`An overview of all your active memberships and benefits`}
      showNewAppointmentLink
    >
      {membershipPrograms && membershipPrograms.length === 0 && (
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            mb: 4
          }}
        >
          <Typography sx={{ mt: 2 }} variant='h6'>
            <Trans>You currently have no membership benefits</Trans>
          </Typography>
        </Box>
      )}

      {membershipPrograms?.map((membershipProgram) => (
        <div key={membershipProgram.id}>
          <Box
            sx={{
              pb: '80px'
            }}
          >
            <Stack direction='column' alignItems='center' mb={4}>
              {membershipProgram.image && (
                <Avatar
                  src={membershipProgram.image}
                  sx={{
                    height: 80,
                    width: 80,
                    mb: 1
                  }}
                />
              )}

              <h3 className='font-bold text-9'>{membershipProgram.name}</h3>
            </Stack>
            <div className='flex w-full flex-col gap-2'>
              {membershipProgram.discounts.map((discount) => (
                <div className='mx-auto w-full max-w-2xl' key={discount.id}>
                  <MembershipDiscount discount={discount} />
                </div>
              ))}
            </div>
          </Box>
          <Divider />
        </div>
      ))}
    </HomeContentWrapper>
  );
};

export default Membership;
