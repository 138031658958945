import { Trans, t } from '@lingui/macro';
import { Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import { URLKeys, noddiAsync } from 'noddi-async';
import { useAuthContext } from 'noddi-provider';
import { LoadingScreen, SectionSpacer } from 'noddi-ui';

import HomeContentWrapper from '../../components/Layouts/HomeContentWrapper';
import Address from './Address';

const Addresses = () => {
  const { getCurrentUserGroupId } = useAuthContext();
  const userGroupId = getCurrentUserGroupId();

  const { isPending: userGroupAddressesIsPending, data: userGroupAddresses } = noddiAsync.useGet({
    type: URLKeys.getUserGroupAddresses,
    input: { userGroupId: userGroupId as number },
    queryConfig: { enabled: !!userGroupId, staleTime: Infinity }
  });

  if (userGroupAddressesIsPending) {
    return <LoadingScreen />;
  }
  if (userGroupAddresses && userGroupAddresses.length === 0) {
    return (
      <HomeContentWrapper title={t`Your addresses`}>
        <SectionSpacer>
          <div style={{ width: '100%' }}>
            <Typography textAlign='center'>
              <Trans>You have no registered addresses with us yet.</Trans>
            </Typography>
          </div>
        </SectionSpacer>
      </HomeContentWrapper>
    );
  }

  return (
    <HomeContentWrapper title={t`Your addresses`}>
      <Stack>
        {userGroupAddresses?.map((userGroupAddress, index) => (
          <Address key={index} userGroupAddress={userGroupAddress} />
        ))}
      </Stack>
    </HomeContentWrapper>
  );
};

export default Addresses;
