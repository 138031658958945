/** @type {import('tailwindcss').Config} */

const primary = {
  darkPurple: '#35155A',
  darkPurple90: '#492C6B',
  darkPurple80: '#5D447B',
  darkPurple70: '#725B8B',
  darkPurple60: '#86739C',
  darkPurple50: '#9A8AAC',
  darkPurple40: '#D7D0DE',
  darkPurple30: '#EBE8EE',

  purple: '#8661C1',
  purple90: '#6D48A7',
  puple80: '#532E8E',
  purple70: '#3A1575',
  purple60: '#20005B',
  purple50: '#070042',
  purple40: '#000028',
  purple30: '#00000F',

  pink: '#FFC7ED',
  pink90: '#FFCDEF',
  pink80: '#FFD2F1',
  pink70: '#FFD8F2',
  pink60: '#FFDDF4',
  pink50: '#FFE3F6',
  pink40: '#FFE9F8',
  pink30: '#FFEEFA',

  orange: '#FF4521',

  white: '#FFFFFF',
  white60: 'rgba(255, 255, 255, 0.6)',
  black: '#000000'
};
const secondary = {
  black: '#1F1F1F',
  white: '#F7F6FA',
  silverGray: '#DADDE0',

  rapsberry: '#C738BD',
  rapsberry90: '#CD4CC4',
  rapsberry80: '#D260CA',
  rapsberry70: '#D874D1',
  rapsberry60: '#DD88D7',
  rapsberry50: '#E39BDE',
  rapsberry40: '#E9AFE5',
  rapsberry30: '#EEC3EB',

  coral: '#FFEAE5',
  coral90: '#FFECE8',
  coral80: '#FFEEEA',
  coral70: '#FFF0ED',
  coral60: '#FFF2EF',
  coral50: '#FFF5F2',
  coral40: '#FFF7F5',
  coral30: '#FFF9F7',

  orange: '#EE634B',
  orange50: '#BB3018'
};

const systemColors = {
  grey: '#7C7B7D',
  snow: '#FFFAFA',

  purpleBg: '#F3EFF9',
  darkPurpleBg: '#F7F7F7',
  rapsberryBg: '#F9EBF8',
  pinkBg: '#FFF4FB',

  outlineStroke: '#EDEEEF',
  transparent: 'transparent'
};

const text = {
  primary: primary.black,
  primaryInverted: '#1F1F1FB2',

  secondary: primary.white,
  seocondaryInverted: '#FFFFFFB2'
};

const signal = {
  success: '#128667',
  success50: '#A0CFC2',

  warning: '#D78E01',
  warning50: '#FFFADE',

  danger: '#EA5147',
  danger50: '#FBDCDA'
};

export const colors = {
  primary,
  secondary,
  systemColors,
  text,
  signal
} as const;

// type that gets all corors from the colors object
export type Colors = typeof colors;

export const gradients = {
  'strongPink-to-lightPink': 'strongPink-to-lightPink',
  'pint-to-lighterPint-gradient': 'pint-to-lighterPint-gradient',
  'pinto-to-white-gradient': 'pinto-to-white-gradient',
  'peach-to-purple': 'peach-to-purple'
} as const;

const sizes = {
  0.25: '1px',
  0: '0px',
  1: '4px',
  1.5: '6px',
  2: '8px',
  2.5: '10px',
  3: '12px',
  3.5: '14px',
  4: '16px',
  4.5: '18px',
  5: '20px',
  5.5: '22px',
  6: '24px',
  7: '28px',
  8: '32px',
  9: '36px',
  10: '40px',
  11: '44px',
  12: '48px',
  13: '52px',
  14: '56px',
  16: '64px',
  17: '68px',
  20: '80px',
  22: '88px',
  25: '100px',
  26: '104px',
  32: '128px',
  40: '160px',
  50: '200px',
  100: '400px',
  150: '600px',
  200: '800px'
};

export default {
  theme: {
    colors,
    spacing: sizes,
    fontFamily: {
      regular: ['"GT America Standard Regular"', 'sans-serif'],
      bold: ['"GT America Standard Bold"', 'sans-serif'],
      condensed: ['"GT America Condensed Black"', 'sans-serif']
    },
    screens: {
      xxs: '400px',
      xs: '475px',
      sm: '640px',
      md: '768px',
      mdx: '900px',
      lg: '1024px',
      xl: '1280px',
      '2xl': '1536px'
    },
    fontSize: sizes,

    extend: {
      boxShadow: {
        cardShadow: '0px 1.92px 15.03px 0px rgba(0, 0, 0, 0.25)'
      },
      backgroundImage: {
        [gradients['pint-to-lighterPint-gradient']]: 'linear-gradient(to bottom, #ECDEFB 0%, #F9F0FD 100%)',
        [gradients['pinto-to-white-gradient']]: 'linear-gradient(to bottom, #ECDEFB 0%, #F9F0FD 14%, #FFFFFF 27%)',
        [gradients['strongPink-to-lightPink']]: 'linear-gradient(to bottom, #F0C6F5 4.42%, #EFE2FE 45.42%)',
        [gradients['peach-to-purple']]: 'linear-gradient(to bottom, #FFEAE5 -1.14%, #F3EFF9 96.11%)'
      },
      keyframes: {
        toastIn: {
          '0%': { opacity: '0', transform: 'translateY(-20px)' },
          '100%': { opacity: '1', transform: 'translateY(0)' }
        },
        toastOut: {
          '0%': { opacity: '1', transform: 'translateY(0)' },
          '100%': { opacity: '0', transform: 'translateY(-20px)' }
        }
      },
      animation: {
        toastInOut: 'toastIn 0.5s ease-out, toastOut 0.5s ease-in 4.5s',
        'spin-slow': 'spin 2s linear infinite'
      }
    }
  },
  safelist: ['embla', 'embla__slide', 'embla__container']
};
