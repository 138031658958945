import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { I18nProvider } from '@lingui/react';
import { ThemeProvider } from '@mui/material/styles';
import * as Sentry from '@sentry/react';
import { noddiAsync } from 'noddi-async';
import {
  AuthProvider,
  LanguageProvider,
  NoddiLocalizationProvider,
  ToastProvider,
  useAuthContext
} from 'noddi-provider';
import { APIBanner, NbFallback, NoddiCircularLoader, createTheme } from 'noddi-ui';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';

import { initLocales } from '../../../packages/config';
import TrackScreen from './TrackScreen';
import routes from './appRoutes';
import { RouterLayout } from './components/Layouts/RouterLayout';
import { VITE_ENGLISH_LOCALE } from './constants/baseUrl';
import { BookingContext } from './contexts/BookingContext';
import ActiveBookingInfo from './pages/BookingInfo';
import { BookingRouter } from './pages/BookingRouter';
import Coupons from './pages/Campaigns';
import CompanySignup from './pages/CompanySignup';
import CompanySignupSuccess from './pages/CompanySignup/CompanySignupSuccess';
import Confirmation from './pages/Confirmation';
import Home from './pages/Home';
import { HomeLayout } from './pages/HomeLayout';
import { HomeRouter } from './pages/HomeRouter';
import Login from './pages/Login';
import MembershipDetails from './pages/MembershipDetail';
import PaymentInfo from './pages/PaymentInfo';
import Referrals from './pages/PublicReferrals';
import { dynamicActivateLocale } from './utils/lingui';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

export const getSupportedLocales = () => ({
  en: { label: t`English`, flag: '🇬🇧' },
  nb: { label: t`Norwegian`, flag: '🇳🇴' }
});

function App() {
  const theme = createTheme();

  const [isLanguageLoaded, setIsLanguageLoaded] = useState(false);
  const [locale, _] = useState<'en' | 'nb'>(VITE_ENGLISH_LOCALE === 'true' ? 'en' : 'nb');

  useEffect(() => {
    initLocales({
      setIsLanguageLoaded,
      defaultLanguage: locale,
      dynamicActivateLocale,
      supportedLocales: getSupportedLocales()
    });
  }, []);

  if (!isLanguageLoaded) {
    return null;
  }

  const NoddiServerContext = noddiAsync.NoddiServerContext;

  return (
    <Sentry.ErrorBoundary fallback={<NbFallback />}>
      <I18nProvider i18n={i18n}>
        <QueryParamProvider
          adapter={ReactRouter6Adapter}
          options={{
            searchStringToObject: queryString.parse,
            objectToSearchString: queryString.stringify
          }}
        >
          <NoddiServerContext>
            <AuthProvider>
              <LanguageProvider defaultLocale={locale} dynamicActivateLocale={dynamicActivateLocale}>
                <ThemeProvider theme={theme}>
                  <ToastProvider defaultSize='small'>
                    <NoddiLocalizationProvider>
                      <BookingContext>
                        <Router />
                        <APIBanner />
                        <TrackScreen />
                      </BookingContext>
                    </NoddiLocalizationProvider>
                  </ToastProvider>
                </ThemeProvider>
              </LanguageProvider>
            </AuthProvider>
          </NoddiServerContext>
        </QueryParamProvider>
      </I18nProvider>
    </Sentry.ErrorBoundary>
  );
}

const Router = () => {
  const { isTokenLoginLoading } = useAuthContext();

  if (isTokenLoginLoading) {
    return (
      <div className='flex min-h-screen justify-center bg-pint-to-lighterPint-gradient'>
        <NoddiCircularLoader />
      </div>
    );
  }
  return (
    <SentryRoutes>
      <Route path='/' element={<RouterLayout />}>
        <Route path={`${routes.newBooking.getBasePath()}`} element={<BookingRouter />} />
        <Route path={routes.campaignsInfo.getBasePath()} element={<Coupons />} />
        <Route path={routes.referralsInfo.getBasePath()} element={<Referrals />} />
        <Route path={routes.paymentInfo.getBasePath()} element={<PaymentInfo />} />
        <Route path={routes.nafMembership.getBasePath()} element={<MembershipDetails />} />
        <Route path={routes.login.getBasePath()} element={<Login />} />
        <Route path='' element={<Navigate replace to={routes.login.getBasePath()} />} />
      </Route>
      <Route path='/' element={<RouterLayout />}>
        <Route path={routes.bookingInfo.getBasePath()} element={<ActiveBookingInfo />} />
      </Route>
      <Route path={routes.companySignup.getBasePath()} element={<CompanySignup />} />
      <Route path={routes.companySignupSuccess.getBasePath()} element={<CompanySignupSuccess />} />
      <Route path='/' element={<HomeLayout />}>
        <Route path={routes.confirmation.getBasePath()} element={<Confirmation />} />
        <Route path={routes.homepage.getBasePath()} element={<Home />} />
        <Route path={routes.home.getBasePath()} element={<HomeRouter />} />
      </Route>
      <Route path='*' element={<Navigate replace to={routes.login.getBasePath()} />} />
    </SentryRoutes>
  );
};

export default App;
