import { t } from '@lingui/macro';
import { Route, Routes } from 'react-router-dom';

import routes from '../appRoutes';
import HomeContentWrapper from '../components/Layouts/HomeContentWrapper';
import TireHotel from './TireHotel';
import TireHotelBookingFlowWrapper from './TireHotel/BookingFlow/Components/TireHotelBookingFlowWrapper';

export function TireStorageRouter() {
  return (
    <Routes>
      <Route
        path='/'
        element={
          <HomeContentWrapper title={t`Tire hotel`}>
            <div className='flex flex-col gap-2'>
              <TireHotel />
            </div>
          </HomeContentWrapper>
        }
      />
      <Route path={routes.tireStorageBooking.getBasePath()} element={<TireHotelBookingFlowWrapper />} />
      <Route path={routes.tireStorageCancelBooking.getBasePath()} element={<TireHotelBookingFlowWrapper isCancel />} />
    </Routes>
  );
}
