import { t } from '@lingui/macro';
import { NoddiAsyncError, NoddiFeedbackBoxSize, mapErrorCodeToUiMessage } from 'noddi-ui';

import { useToast } from '../contexts/ToastProvider';

const useNoddiToast = () => {
  const { addToast } = useToast();

  const success = (heading: string, description?: string, size?: NoddiFeedbackBoxSize) =>
    addToast('success', heading, description, size);
  const error = (heading: string, description?: string, size?: NoddiFeedbackBoxSize) =>
    addToast('error', heading, description, size);
  const warning = (heading: string, description?: string, size?: NoddiFeedbackBoxSize) =>
    addToast('warning', heading, description, size);
  const successfullyCreated = (size?: NoddiFeedbackBoxSize) => success(t`Successfully created!`, undefined, size);
  const successfullyUpdated = (size?: NoddiFeedbackBoxSize) => success(t`Successfully updated!`, undefined, size);
  const asyncError = (e: NoddiAsyncError, size?: NoddiFeedbackBoxSize) =>
    error(mapErrorCodeToUiMessage({ error: e }), undefined, size);

  const noddiToast = { success, error, warning, successfullyCreated, successfullyUpdated, asyncError };

  return { noddiToast };
};

export { useNoddiToast };
