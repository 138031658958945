import { Trans, t } from '@lingui/macro';
import { Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import { URLKeys, noddiAsync } from 'noddi-async';
import { useAuthContext } from 'noddi-provider';
import { useNoddiToast } from 'noddi-provider/src/utils/toast';
import {
  ApiErrorMessage,
  LoadingScreen,
  NoddiButton,
  NoddiFeedbackBox,
  OrderSummary,
  getLongAddressName
} from 'noddi-ui';
import { DateFormats, format } from 'noddi-util';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import routes from '../../../appRoutes';
import HomeContentWrapper from '../../../components/Layouts/HomeContentWrapper';
import { CancelDialog } from './CancelDialog';
import EditComment from './EditComment';
import { MetaSection } from './MetaSection';
import NoBookingDataFound from './NoBookingDataFound';

const BookingDetails = () => {
  const { currentUserGroupId: userGroupId } = useAuthContext();
  const navigate = useNavigate();
  const { id: bookingId } = useParams();
  const [cancelDialogIsOpen, setCancelDialogIsOpen] = useState(false);
  const { noddiToast } = useNoddiToast();

  const bookingIdAsString = bookingId as string;
  const {
    isPending: isBookingPending,
    data: bookingData,
    isError,
    refetch: refetchBookingData
  } = noddiAsync.useGet({
    type: URLKeys.getUserBooking,
    input: { userGroupId: userGroupId as number, bookingId: bookingIdAsString },
    queryConfig: {
      enabled: !!userGroupId && !!bookingId
    }
  });

  const { mutateAsync: cancelBooking, isPending: isCancelling } = noddiAsync.usePost({
    type: URLKeys.getCancelBooking,
    queryConfig: {
      onSuccess: () => {
        refetchBookingData();
      }
    }
  });

  const bookingIdNumber = Number(bookingId);

  const {
    isRefetching: isDownloadingReceiptRefetching,
    refetch: downloadReceipt,
    error: downloadReceiptError
  } = noddiAsync.useGet({
    type: URLKeys.downloadBookingReceipt,
    input: {
      bookingId: bookingIdNumber
    },
    queryConfig: {
      enabled: false
    }
  });

  const {
    isPending: isBookingPermissionsPending,
    data: bookingPermissions,
    isError: isBookingPermissionsError
  } = noddiAsync.useGet({
    type: URLKeys.getEditBookingPermissions,
    input: { id: bookingIdAsString },
    queryConfig: {
      enabled: !!bookingId
    }
  });

  const getDateText = () => {
    if (bookingData?.completedAt) {
      return `${t`Delivered :`} ${format(bookingData?.completedAt, DateFormats.FULL_MONTH_YEAR_DATE_TIME)}`;
    } else if (bookingData?.startedAt) {
      return `${t`Started :`} ${format(bookingData?.startedAt, DateFormats.FULL_MONTH_YEAR_DATE_TIME)}`;
    } else if (bookingData?.bookingTimeWindow) {
      return `${t`Scheduled :`} ${format(
        bookingData?.bookingTimeWindow.startPublic,
        DateFormats.FULL_MONTH_YEAR_DATE_TIME
      )} - ${format(bookingData?.bookingTimeWindow.endPublic, DateFormats.TIME)}`;
    } else {
      return '?';
    }
  };

  if (isBookingPending || isCancelling || isBookingPermissionsPending) {
    return <LoadingScreen />;
  }
  if (isError || !bookingData || isBookingPermissionsError) {
    return <NoBookingDataFound />;
  }

  function navigateToEditBookingTimeWindow(id: number) {
    navigate(routes.editMyBookingTimeWindow.getPath({ id }));
  }

  const showActionButtons = !bookingData?.isCancelled && !bookingData?.isCancelClosed;
  const showPaymentButton = bookingData?.completedAt && bookingData.amountDue && bookingData.amountDue > 0;

  return (
    <HomeContentWrapper title={t`Your booking`}>
      <div className='flex flex-col'>
        <div className='flex justify-end gap-4'>
          {showActionButtons ? (
            <Stack direction='row' gap={2} alignItems='center'>
              {bookingPermissions.timeWindows.canEdit && (
                <NoddiButton
                  variant='secondary'
                  onClick={() => navigateToEditBookingTimeWindow(bookingData.id)}
                  startIcon='Edit'
                >
                  <Typography variant='body1'>
                    <Trans>Change time</Trans>
                  </Typography>
                </NoddiButton>
              )}
              <NoddiButton
                variant='secondary'
                startIcon='CloseRounded'
                onClick={() => setCancelDialogIsOpen(true)}
                disabled={isCancelling}
              >
                <Trans>Cancel</Trans>
              </NoddiButton>
              <CancelDialog
                open={cancelDialogIsOpen}
                onClose={() => setCancelDialogIsOpen(false)}
                bookingIdNumber={bookingIdNumber}
                cancelBooking={cancelBooking}
              />
            </Stack>
          ) : (
            !bookingData?.isCancelled &&
            !bookingData?.completedAt && (
              <NoddiFeedbackBox
                size='small'
                heading={t`This booking cannot be changed or canceled due to the proximity to the start time. For questions, contact us at hei@noddi.no`}
                variant='info'
              />
            )
          )}
          {showPaymentButton ? (
            <NoddiButton onClick={() => navigate(routes.bookingInfo.getPath({ slug: bookingData?.slug }))}>
              <Trans>Pay</Trans>
            </NoddiButton>
          ) : null}
          {bookingData?.hasReceipt && (
            <div className='flex flex-col items-end'>
              {downloadReceiptError && (
                <div className='mb-3'>
                  <ApiErrorMessage error={downloadReceiptError} isSmallVersion />
                </div>
              )}

              <NoddiButton
                loading={isDownloadingReceiptRefetching}
                endIcon='Download'
                variant='secondary'
                onClick={async () => {
                  const { error } = await downloadReceipt();

                  if (error) {
                    return noddiToast.error(t`Could not download receipt`);
                  }

                  return refetchBookingData();
                }}
              >
                <Trans>Receipt</Trans>
              </NoddiButton>
            </div>
          )}
        </div>
        <div className='mt-4'>
          {bookingData && (
            <OrderSummary
              MetaSection={
                <>
                  <MetaSection
                    isPaid={bookingData?.hasReceipt}
                    isCancelled={bookingData?.isCancelled}
                    slug={bookingData?.slug}
                    address={bookingData?.address ? getLongAddressName(bookingData.address) : undefined}
                    getDateText={getDateText}
                  />
                  <EditComment commentsUser={bookingData.commentsUser ?? ''} bookingId={bookingIdAsString} />
                </>
              }
              carItemLines={bookingData.cars}
              otherOrderLines={bookingData.otherOrderLines}
              discountLines={bookingData.discountOrderLines}
            />
          )}
        </div>
      </div>
    </HomeContentWrapper>
  );
};

export default BookingDetails;
