import { Trans, t } from '@lingui/macro';
import Typography from '@mui/material/Typography';
import { Form, Formik } from 'formik';
import { URLKeys, noddiAsync } from 'noddi-async';
import { NoddiAsyncError, UserData } from 'noddi-async/src/types';
import { useAuthContext } from 'noddi-provider';
import { useEffect, useRef } from 'react';

import { ApiErrorMessage, NoddiButton, NoddiFormTextInput, SectionSpacer } from '../../Elements';
import userRegistrationFormSchema from './validators';

interface IFormInput {
  firstName: string;
  surname: string;
  email: string;
  password?: string;
  confirmPassword?: string;
  orgName?: string;
  orgNumber?: string;
}

type Props = {
  phoneNumber: string;
  skipPasswordCreation?: boolean;
  trackUserIdentity?: (userData: UserData) => void;
  customCTAText?: string;
  orgProperties?: {
    orgName: string;
    orgNumber: string;
  };
};

const UserRegistrationForm = ({
  phoneNumber,
  skipPasswordCreation,
  trackUserIdentity,
  customCTAText,
  orgProperties
}: Props) => {
  const { loginUser } = useAuthContext();
  const commonInitialValues = {
    firstName: '',
    surname: '',
    email: ''
  };

  const initialValues = skipPasswordCreation
    ? commonInitialValues
    : { ...commonInitialValues, password: '', confirmPassword: '' };

  const extendedInitialValues = orgProperties ? { ...initialValues, ...orgProperties } : initialValues;

  const {
    mutateAsync: createUser,
    isPending: isCreateUserPending,
    error: createUserError
  } = noddiAsync.usePost({
    type: URLKeys.postUser,
    queryConfig: {
      onSuccess: ({ data }) => {
        trackUserIdentity?.(data.user);
        loginUser(data);
      }
    }
  });

  const {
    mutateAsync: createOrgUser,
    isPending: isCreateOrgUserPending,
    error: orgUserError
  } = noddiAsync.usePost({
    type: URLKeys.postUserWithOrganization,
    queryConfig: {
      onSuccess: ({ data }) => {
        trackUserIdentity?.(data.user);
        loginUser(data);
      }
    }
  });

  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    ref.current?.focus();
    ref.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'center'
    });
  }, [ref]);

  const ctaText = customCTAText ?? t`Create user`;
  const hasError = createUserError ?? orgUserError;

  return (
    <Formik
      initialValues={extendedInitialValues}
      onSubmit={async (data: IFormInput) => {
        const commonPayload = {
          firstName: data.firstName,
          lastName: data.surname,
          phoneNumber: phoneNumber,
          email: data.email,
          password: data.password,
          confirmPassword: data.confirmPassword
        };
        if (orgProperties) {
          await createOrgUser({
            ...commonPayload,
            organizationName: orgProperties.orgName,
            organizationNumber: orgProperties.orgNumber
          });
          return;
        } else {
          await createUser(commonPayload);
        }
      }}
      validationSchema={userRegistrationFormSchema(skipPasswordCreation)}
    >
      {({ isValid, isSubmitting }) => (
        <Form>
          <Typography variant='h4'>
            <Trans>User information</Trans>
          </Typography>
          <SectionSpacer fullWidth variant='small'>
            <NoddiFormTextInput ref={ref} name='firstName' label={t`First name`} fullWidth />
          </SectionSpacer>
          <SectionSpacer fullWidth variant='small'>
            <NoddiFormTextInput name='surname' label={t`Surname`} />
          </SectionSpacer>
          <SectionSpacer fullWidth variant='small'>
            <NoddiFormTextInput name='email' label={t`E-mail address`} placeholder={t`test@example.com`} />
          </SectionSpacer>
          {skipPasswordCreation ? null : (
            <>
              <SectionSpacer fullWidth variant='small'>
                <NoddiFormTextInput autoComplete='new-password' name='password' label={t`Password`} type='password' />
              </SectionSpacer>
              <SectionSpacer fullWidth variant='small'>
                <NoddiFormTextInput
                  autoComplete='new-password'
                  name='confirmPassword'
                  label={t`Confirm password`}
                  type='password'
                />
              </SectionSpacer>
            </>
          )}
          {hasError && <ApiErrorMessage error={hasError as NoddiAsyncError} />}
          <NoddiButton
            type='submit'
            className='mt-6'
            fullWidth
            disabled={!isValid}
            loading={isCreateUserPending || isSubmitting || isCreateOrgUserPending}
          >
            {ctaText}
          </NoddiButton>
        </Form>
      )}
    </Formik>
  );
};

export { UserRegistrationForm };
