import { Trans } from '@lingui/macro';
import { EmblaCarouselType } from 'embla-carousel';
import { useCallback, useEffect, useState } from 'react';

import { NoddiButton } from '../../Elements';

type SliderArrowProps = {
  slider: EmblaCarouselType | undefined;
};

type ForwardArrowProps = {
  onNextButtonClick: () => void;
  onPrevButtonClick: () => void;
  prevBtnDisabled: boolean;
  nextBtnDisabled: boolean;
  isMobile?: boolean;
};

type SliderArrowsProps = SliderArrowProps & ForwardArrowProps;

const getBackArrow = ({
  slider,
  onPrevButtonClick,
  prevBtnDisabled,
  isMobile
}: SliderArrowProps & ForwardArrowProps) => {
  if (!slider || (prevBtnDisabled && isMobile)) {
    return null;
  }

  return (
    <NoddiButton
      variant='secondary'
      size='small'
      onClick={() => onPrevButtonClick()}
      disabled={prevBtnDisabled}
      startIcon='AltArrowLeft'
    >
      <Trans>Earlier</Trans>
    </NoddiButton>
  );
};

const getForwardArrow = ({ slider, onNextButtonClick, nextBtnDisabled }: SliderArrowProps & ForwardArrowProps) => {
  if (!slider) {
    return null;
  }

  return (
    <NoddiButton
      size='small'
      variant='secondary'
      onClick={() => onNextButtonClick()}
      endIcon='AltArrowRight'
      disabled={nextBtnDisabled}
    >
      <Trans>Later</Trans>
    </NoddiButton>
  );
};

export const SliderArrows = ({ slider, ...rest }: SliderArrowsProps) => {
  if (!slider) {
    return null;
  }

  return (
    <div className='flex items-start gap-3 max-sm:justify-between'>
      {getBackArrow({ slider, ...rest })}
      {getForwardArrow({ slider, ...rest })}
    </div>
  );
};

type UsePrevNextButtonsType = {
  prevBtnDisabled: boolean;
  nextBtnDisabled: boolean;
  onPrevButtonClick: () => void;
  onNextButtonClick: () => void;
};

export const usePrevNextButtons = (emblaApi: EmblaCarouselType | undefined): UsePrevNextButtonsType => {
  const [prevBtnDisabled, setPrevBtnDisabled] = useState(true);
  const [nextBtnDisabled, setNextBtnDisabled] = useState(true);

  const onPrevButtonClick = useCallback(() => {
    if (!emblaApi) {
      return;
    }
    emblaApi.scrollPrev();
  }, [emblaApi]);

  const onNextButtonClick = useCallback(() => {
    if (!emblaApi) {
      return;
    }
    emblaApi.scrollNext();
  }, [emblaApi]);

  const onSelect = useCallback((emblaApi: EmblaCarouselType) => {
    setPrevBtnDisabled(!emblaApi.canScrollPrev());
    setNextBtnDisabled(!emblaApi.canScrollNext());
  }, []);

  useEffect(() => {
    if (!emblaApi) {
      return;
    }

    onSelect(emblaApi);
    emblaApi.on('reInit', onSelect).on('select', onSelect);
  }, [emblaApi, onSelect]);

  return {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick
  };
};
