/**
 * Works much like the {@link AddressPicker.tsx}, but this also validates that we can delivery our services there
 **/
import { Trans, t } from '@lingui/macro';
import { URLKeys, noddiAsync } from 'noddi-async';
import { AddressProps } from 'noddi-async/src/types';
import { useEffect, useState } from 'react';

import { ErrorMessage, NoddiLinearProgressLoader, TypographyWithInfoSign } from '../../Elements';
import { AddressPicker, AddressPickerProps } from './AddressPicker';
import { hasStreetNumber } from './addressUtils';

type ClearAddress = { clearAddress: () => void };

const AvailableAddressPicker = ({ clearAddress, onSelectAddress, ...params }: AddressPickerProps & ClearAddress) => {
  const [selectedAddress, setSelectedAddress] = useState<AddressProps | null>(null);

  const {
    isLoading: isServiceAreasLoading,
    isError: isServiceAreaError,
    refetch: refetchServiceAreas,
    data: serviceAreas
  } = noddiAsync.useGet({
    type: URLKeys.getServiceAreasFromCoordinate,
    input: { lat: selectedAddress?.latitude, lng: selectedAddress?.longitude },
    queryConfig: {
      enabled: Boolean(selectedAddress)
    }
  });

  const hasServiceCategories = Boolean(serviceAreas?.serviceCategories?.length ?? 0 > 0);

  useEffect(() => {
    if (selectedAddress) {
      refetchServiceAreas();
    }
  }, [selectedAddress]);

  useEffect(() => {
    if (selectedAddress && serviceAreas) {
      if (!hasServiceCategories) {
        return clearAddress();
      }

      return onSelectAddress(selectedAddress);
    }
  }, [serviceAreas]);

  return (
    <>
      <AddressPicker
        onSelectAddress={async (address) => {
          if (!address) {
            return;
          }
          setSelectedAddress(address);
        }}
        {...params}
      />
      {isServiceAreasLoading && <NoddiLinearProgressLoader />}
      {serviceAreas && !hasServiceCategories && (
        <TypographyWithInfoSign>
          <Trans>
            Sorry, we do not currently deliver services to your address. Try a different address, or again at a later
            time.
          </Trans>
        </TypographyWithInfoSign>
      )}
      {serviceAreas && !hasStreetNumber(selectedAddress) && (
        <TypographyWithInfoSign>
          <Trans>The address you provided does not have a street number, which is required to continue</Trans>
        </TypographyWithInfoSign>
      )}
      {isServiceAreaError && (
        <ErrorMessage message={t`Something went wrong with fetching the addresses, please try again`} />
      )}
    </>
  );
};

export { AvailableAddressPicker };
