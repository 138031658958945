import { cn } from 'noddi-ui/src/helpers/utilts';
import { PropsWithChildren } from 'react';

type HomeCardWrapperProps = {
  className?: string;
};

const HomeCardWrapper = ({ children, className }: PropsWithChildren<HomeCardWrapperProps>) => {
  return (
    <div className={cn('rounded-2xl bg-primary-white px-5 py-4 text-primary-darkPurple shadow-md', className)}>
      {children}
    </div>
  );
};

export default HomeCardWrapper;
