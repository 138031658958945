import { Trans } from '@lingui/macro';

import { BasicHeaderNav } from '../../components/Layouts/BasicHeaderNav';
import HomeContentWrapper from '../../components/Layouts/HomeContentWrapper';

const CompanySignupSuccess = () => {
  return (
    <div className='min-h-screen bg-peach-to-purple'>
      <BasicHeaderNav />
      <HomeContentWrapper>
        <div>
          <p className='mt-20 font-bold text-10 text-primary-darkPurple'>
            <Trans>Thank you! </Trans>
          </p>
          <p className='mt-7 font-bold text-7 text-primary-purple'>
            <Trans>We will be in touch with you shortly</Trans>🚀
          </p>
          <p className='mt-4 text-primary-darkPurple'>
            <Trans>If you have any questions, contact us at bedrift@noddi.no</Trans>
          </p>
        </div>
      </HomeContentWrapper>
    </div>
  );
};

export default CompanySignupSuccess;
