import { t } from '@lingui/macro';
import { ServerTypes } from 'noddi-async';

import { SelectedSalesItem } from '../../interfaces';

export enum BookingCategory {
  WHEEL_SERVICES = 'wheel_services',
  WASH_SERVICES = 'wash_services',
  PACKAGES = 'packages'
}

export const translateBookingCategories = (bookingCategoryFromApi: BookingCategory | string): string => {
  switch (bookingCategoryFromApi) {
    case BookingCategory.WHEEL_SERVICES:
      return t`Wheel services`;
    case BookingCategory.WASH_SERVICES:
      return t`Wash services`;
    case BookingCategory.PACKAGES:
      return t`Packages`;
    default:
      return `Other`;
  }
};

export const bookingCategoryLabelToEnum = (serviceFromApi: BookingCategory | string): BOOKING_CATEGORY_TYPE => {
  switch (serviceFromApi) {
    case BookingCategory.WHEEL_SERVICES:
      return BOOKING_CATEGORY_TYPE.WHEEL_SERVICES;
    case BookingCategory.WASH_SERVICES:
      return BOOKING_CATEGORY_TYPE.WASHING_SERVICES;
    case BookingCategory.PACKAGES:
      return BOOKING_CATEGORY_TYPE.PACKAGES;
    default:
      return BOOKING_CATEGORY_TYPE.PACKAGES;
  }
};

export enum BOOKING_CATEGORY_TYPE {
  WHEEL_SERVICES,
  WASHING_SERVICES,
  PACKAGES
}

export const getNumberOfSelectedSalesItems = ({
  selectedSalesItems,
  category,
  salesItems,
  licensePlateNumber
}: {
  selectedSalesItems: SelectedSalesItem[];
  category: string;
  licensePlateNumber: string;
  salesItems: ServerTypes.AvailableSalesItem[];
}) => {
  const salesItemsInCategory = salesItems.filter((item) => item.bookingCategory?.slug === category);
  return salesItemsInCategory.filter((item) =>
    selectedSalesItems.find(
      (selectedItem) => selectedItem.licensePlateNumber === licensePlateNumber && selectedItem.id === item.id
    )
  ).length;
};
