import { Trans, t } from '@lingui/macro';
import { Card, CardContent, Stack, Typography } from '@mui/material';
import { WheelProps } from 'noddi-async/src/types';
import { DateFormats, format } from 'noddi-util';

import { KeyValueRow } from '../../../Elements';
import {
  WheelPosition,
  calculateWheelStatus,
  findLatestWheelMeasurement,
  resolveWheelSetStatusLogo,
  wheelSetStatusToTranslation
} from '../helpers';
import { TireType, WheelSetStatus, WheelSetStatusType } from '../types';

type Props = {
  wheels: WheelProps[];
  tireType: TireType;
};
const TireMeasurement = ({ wheels, tireType }: Props) => {
  const leftFront = t`Left front`;
  const rightFront = t`Right front`;
  const leftBack = t`Left back`;
  const rightBack = t`Right back`;

  const formatWithUnit = (value: number | undefined, unit: string) => {
    return value ? `${value} ${unit}` : '-';
  };
  return (
    <div>
      <p className='mb-2 font-semibold'>
        {tireType === 'summer' ? <Trans>Summer tires</Trans> : <Trans>Winter tires</Trans>}
      </p>
      <Card>
        <CardContent>
          <KeyValueRow
            header={
              <CarDetailHeader
                position={leftFront}
                wheelSetStatus={calculateWheelStatus(wheels, WheelPosition.FrontLeft, tireType)}
              />
            }
            value={<p>{formatWithUnit(findLatestWheelMeasurement(wheels, WheelPosition.FrontLeft)?.value, 'mm')}</p>}
          />
          <KeyValueRow
            header={
              <CarDetailHeader
                position={rightFront}
                wheelSetStatus={calculateWheelStatus(wheels, WheelPosition.FrontRight, tireType)}
              />
            }
            value={formatWithUnit(findLatestWheelMeasurement(wheels, WheelPosition.FrontRight)?.value, 'mm')}
          />
          <KeyValueRow
            header={
              <CarDetailHeader
                position={leftBack}
                wheelSetStatus={calculateWheelStatus(wheels, WheelPosition.RearLeft, tireType)}
              />
            }
            value={formatWithUnit(findLatestWheelMeasurement(wheels, WheelPosition.RearLeft)?.value, 'mm')}
          />
          <KeyValueRow
            header={
              <CarDetailHeader
                position={rightBack}
                wheelSetStatus={calculateWheelStatus(wheels, WheelPosition.RearRight, tireType)}
              />
            }
            value={formatWithUnit(findLatestWheelMeasurement(wheels, WheelPosition.RearRight)?.value, 'mm')}
            showDivider={false}
          />

          <MeasuredAt wheels={wheels} />
        </CardContent>
      </Card>
    </div>
  );
};

function CarDetailHeader({ position, wheelSetStatus }: { position: string; wheelSetStatus: WheelSetStatusType }) {
  const isSatisfactory = wheelSetStatus === WheelSetStatus.Satisfactory;
  const isMissingMeasurements = wheelSetStatus === WheelSetStatus.MissingMeasurements;
  return (
    <div className='flex items-center gap-2'>
      <div className='w-22'>
        <p>{position}</p>
      </div>
      {/* only show icon if not satisfactory */}
      {!isSatisfactory && !isMissingMeasurements && (
        <Stack direction='row' gap={0.5} paddingY={1}>
          {resolveWheelSetStatusLogo(wheelSetStatus)}
          <p>{wheelSetStatusToTranslation(wheelSetStatus)}</p>
        </Stack>
      )}
    </div>
  );
}

function MeasuredAt({ wheels }: { wheels: WheelProps[] }) {
  const measuredAt = findLatestWheelMeasurement(wheels, WheelPosition.RearRight)?.measuredAt;
  return measuredAt ? (
    <Typography color='text.secondary' variant='body2' marginTop={1}>
      <Trans>Measured at </Trans>
      {` ${format(measuredAt, DateFormats.DOTTED_DATE)}`}
    </Typography>
  ) : null;
}
export { TireMeasurement };
