import { Error404Page } from 'noddi-ui';
import { Route, Routes } from 'react-router-dom';

import routes from '../appRoutes';
import Addresses from './Addresses';
import Bonus from './Bonus';
import { Bookings } from './Bookings';
import BookingDetails from './Bookings/[id]';
import EditBookingTimeWindow from './Bookings/[id]/EditBookingTimeWindow';
import CarDetailPage from './Cars/CarDetailPage';
import WheelMeasureMentPage from './Cars/WheelMeasureMentPage';
import Membership from './Memberships';
import Profile from './Profile';
import { TireHotelProvider } from './TireHotel/BookingFlow/context';
import { TireStorageRouter } from './TireStorageRouter';

export function HomeRouter() {
  return (
    <Routes>
      <Route path='/'>
        <Route path={routes.myBookingDetails.getBasePath()} element={<BookingDetails />} />

        <Route path={routes.myCarDetails.getBasePath()} element={<CarDetailPage />} />
        <Route path={routes.myCarWheelMeasurements.getBasePath()} element={<WheelMeasureMentPage />} />
        <Route path={routes.myCoupons.getBasePath()} element={<Bonus />} />
        <Route path='*' element={<Error404Page />} />
        <Route path={routes.homeError.getBasePath()} element={<Error404Page />} />
        <Route path={routes.profile.getBasePath()} element={<Profile />} />
        <Route path={routes.myMembershipPrograms.getBasePath()} element={<Membership />} />
        <Route path={routes.bookings.getBasePath()} element={<Bookings />} />
        <Route path={routes.myAddresses.getBasePath()} element={<Addresses />} />

        <Route
          path={`${routes.tireStorage.getBasePath()}/*`}
          element={
            <TireHotelProvider>
              <TireStorageRouter />
            </TireHotelProvider>
          }
        />
      </Route>
      <Route path='/'>
        <Route path={routes.editMyBookingTimeWindow.getBasePath()} element={<EditBookingTimeWindow />} />
      </Route>
    </Routes>
  );
}
