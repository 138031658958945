import { Trans } from '@lingui/macro';

import NewAppointmentLink from '../Links/NewAppointment';
import HomeCardWrapper from './HomeCardWrapper';
import NoUpcomingBookingSvg from './NoUpcomingBookingsSvg';

const NoBookingsCard = () => {
  return (
    <div className='flex flex-col gap-2 md:gap-4'>
      <h2 className='font-bold text-6 text-primary-darkPurple md:text-8'>
        <Trans>No bookings</Trans>
      </h2>
      <HomeCardWrapper className='flex flex-col gap-3'>
        <p>
          <Trans>
            Tell us where your car is parked. We'll come to you, so you can spend your time on other things.
          </Trans>
        </p>

        <NoUpcomingBookingSvg />

        <NewAppointmentLink fullWidth />
      </HomeCardWrapper>
    </div>
  );
};
export default NoBookingsCard;
