import { captureException } from '@sentry/react';
import { NoddiAsyncError } from 'noddi-async/src/types';
import { useEffect } from 'react';

export const useCaptureException = (error?: NoddiAsyncError | null | string, title?: string) => {
  useEffect(() => {
    if (!error) {
      return;
    }

    if (typeof error === 'string') {
      const errorToSend = new Error(error);
      errorToSend.name = title || 'UnknownError';
      captureException(errorToSend); // Capture simple string errors
    } else if (error && error.isAxiosError) {
      // Handle AxiosError specifically
      const axiosError = error as NoddiAsyncError;
      const errorMessage = axiosError.response?.data?.errors?.[0]?.details || axiosError.message;
      const errorCode = axiosError?.status || 'UnknownCode';
      const errorInstance = new Error(errorMessage);
      errorInstance.name = `Axios Error: ${errorCode}`;
      captureException(errorInstance);
    } else if (error instanceof Error) {
      captureException(error); // Handle standard JS errors
    }
  }, [error]);
};
