import { URLKeys, noddiAsync } from 'noddi-async';
import { useAuthContext } from 'noddi-provider';
import { ErrorPage, LoadingScreen } from 'noddi-ui';

import { TireHotelProvider } from './BookingFlow/context';
import ContractList from './ContractList';

const TireHotel = () => {
  const { currentUserGroupId } = useAuthContext();

  const {
    data: tireHotelContracts,
    isPending,
    error
  } = noddiAsync.useGet({
    type: URLKeys.getTireHotelContracts,
    input: { userGroupId: currentUserGroupId as number },
    queryConfig: {
      enabled: !!currentUserGroupId,
      staleTime: Infinity
    }
  });

  const vinNumbers =
    tireHotelContracts?.map((contract) => contract.car.vinNumber).filter((x): x is string => Boolean(x)) ?? [];

  const {
    data: tireHotelOptions,
    isLoading: optionsIsLoading,
    error: optionsError
  } = noddiAsync.useGet({
    type: URLKeys.getTireHotelOptions,
    input: { vinNumbers, userGroupId: currentUserGroupId },
    queryConfig: {
      enabled: vinNumbers.length > 0,
      staleTime: Infinity
    }
  });

  if (isPending || optionsIsLoading) {
    return <LoadingScreen />;
  }

  if (!currentUserGroupId || error || optionsError) {
    return <ErrorPage apiError={[error, optionsError]} />;
  }

  if (tireHotelContracts?.length === 0) {
    return null;
  }

  return (
    <TireHotelProvider>
      <ContractList tireHotelContracts={tireHotelContracts} tireHotelOptions={tireHotelOptions ?? []} />
    </TireHotelProvider>
  );
};

export default TireHotel;
