import { t } from '@lingui/macro';
import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { MembershipProgramProps } from 'noddi-async/src/types';
import { NoddiIcon, NoddiTextAreaInput, getLongAddressName } from 'noddi-ui';
import { timeWindowToDisplayString } from 'noddi-util';
import { ReactNode } from 'react';

import BookingSummary from '../../../../../components/BookingFlow/Summary';
import { useBookingContext } from '../../../../../contexts/BookingContext';

type Props = {
  membershipData: MembershipProgramProps[] | undefined;
};

const SummaryItem = ({ icon, children }: { icon: ReactNode; children: ReactNode }) => {
  return (
    <Stack alignItems='center' direction='row' spacing={1}>
      {icon}
      <Typography variant='body2' fontWeight='normal'>
        {children}
      </Typography>
    </Stack>
  );
};

const ConfirmBooking = ({ membershipData }: Props) => {
  const { bookingInputData, updateBookingInputData } = useBookingContext();
  return (
    <div className='gap-4 rounded-lg bg-primary-white p-4'>
      {bookingInputData.address && (
        <SummaryItem icon={<NoddiIcon name='LocationPin' size='medium' />}>
          {getLongAddressName(bookingInputData.address)}
        </SummaryItem>
      )}
      {bookingInputData.time && (
        <SummaryItem icon={<NoddiIcon name='ClockCircle' size='medium' />}>
          {timeWindowToDisplayString(
            new Date(bookingInputData.time.startPublic),
            new Date(bookingInputData.time.endPublic)
          )}
        </SummaryItem>
      )}
      <BookingSummary membershipData={membershipData} />

      <NoddiTextAreaInput
        label={t`Comment`}
        placeholder={t`Instructions to the technician at arrival (e.g. where the car is parked)`}
        value={bookingInputData.comment || ''}
        onChange={(event) => updateBookingInputData({ comment: event.target.value })}
      />
    </div>
  );
};

export default ConfirmBooking;
