import { Trans, t } from '@lingui/macro';
import { useNoddiToast } from 'noddi-provider/src/utils';
import { NoddiIconButton } from 'noddi-ui';

import HomeCardWrapper from '../../components/Home/HomeCardWrapper';

const CopyReferralLink = ({ referralCode }: { referralCode: string }) => {
  const { noddiToast } = useNoddiToast();

  const referralLink = `app.noddi.no/referrals/${referralCode}`;

  const copyToClipboard = () => {
    navigator.clipboard.writeText(referralLink);
    noddiToast.success(t`Referral link was copied`);
  };

  return (
    <HomeCardWrapper className='flex flex-col gap-4 bg-primary-purple text-primary-white'>
      <p className='font-bold text-5'>
        <Trans>My refferal link</Trans> 🎉
      </p>

      <div className='flex'>
        <div className='w-full rounded-lg border border-primary-white px-3 py-1.5'>
          <p className='truncate'>{referralLink}</p>
        </div>
        <div>
          <NoddiIconButton iconName='Copy' iconSize='medium' onClick={copyToClipboard}></NoddiIconButton>
        </div>
      </div>
    </HomeCardWrapper>
  );
};

export default CopyReferralLink;
