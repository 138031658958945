import { Dialog, DialogContent, DialogContentProps, DialogProps, DialogTitle } from '@mui/material';
import { PropsWithChildren } from 'react';

import { NoddiIconButton } from '../../../molecules/NoddiIconButton';

const NoddiDialog = ({
  children,
  contentProps,
  onClose,
  open,
  title,
  ...dialogProps
}: PropsWithChildren<DialogProps> & { contentProps?: DialogContentProps }) => {
  return (
    <Dialog fullWidth open={open} onClose={onClose} {...dialogProps}>
      <DialogTitle>
        <div className={`flex items-center gap-4 ${title ? 'justify-between' : 'justify-end'}`}>
          {title && <h2 className='font-regular text-6'>{title}</h2>}
          {onClose && (
            <div onClick={(e) => onClose(e, 'escapeKeyDown')}>
              <NoddiIconButton iconName='Cross' variant='ghost' />
            </div>
          )}
        </div>
      </DialogTitle>
      <DialogContent {...contentProps}>{children}</DialogContent>
    </Dialog>
  );
};

export { NoddiDialog };
