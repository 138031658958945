import { Trans } from '@lingui/macro';
import { ErrorPage, NoddiButton, NoddiCircularLoader } from 'noddi-ui';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import routes from '../../appRoutes';
import { useMembershipPrograms } from '../BookingFlow/hooks/useMembershipPrograms';
import MembershipDiscount from '../Memberships/MembershipDiscount';

interface MembershipAddedProps {
  membershipName?: string | null;
}

const MembershipAdded = ({ membershipName }: MembershipAddedProps) => {
  const navigate = useNavigate();
  const { data: membershipData, isLoading, isError: membershipProgramsError } = useMembershipPrograms();

  const memberShipProgramToShow = useMemo(
    () => membershipData?.find((membershipProgram) => membershipProgram.name === membershipName),
    [membershipData, membershipName]
  );

  const handleCTAClick = () => {
    navigate(routes.newBookingAddressStep.getPath());
  };

  if (membershipProgramsError) {
    return <ErrorPage />;
  }

  return (
    <div className='mx-auto mt-10 flex max-w-2xl flex-col gap-4 p-4'>
      <h1 className='text-7 sm:text-13'>
        <Trans>Membership registered </Trans>🎊
      </h1>

      {isLoading && <NoddiCircularLoader />}
      {memberShipProgramToShow && (
        <div className='mt-2'>
          <div className='flex flex-col gap-3'>
            {memberShipProgramToShow.discounts.map((discount, discountIndex) => (
              <div key={discountIndex} className='w-full'>
                <MembershipDiscount discount={discount} />
              </div>
            ))}
          </div>
        </div>
      )}

      <NoddiButton fullWidth onClick={handleCTAClick} endIcon='ArrowRight'>
        <Trans>New booking</Trans>
      </NoddiButton>
    </div>
  );
};

export default MembershipAdded;
