import { Trans, t } from '@lingui/macro';
import { NoddiAsyncError } from 'noddi-async/src/types';

import { NoddiFeedbackBox } from '../../../molecules';
import { NoddiButton } from '../Buttons';
import { mapErrorCodeToUiMessage } from './ErrorMessage';
import { useCaptureException } from './utils';

type ErrorPageProps = {
  errorTitle?: string;
  errorMessage?: string;
  apiError?: NoddiAsyncError | Array<NoddiAsyncError | null>;
  customCallback?: { ctaText: string; callback: () => void };
};

const ErrorPage = ({ errorMessage, errorTitle, apiError, customCallback }: ErrorPageProps) => {
  const errorToRender = Array.isArray(apiError) ? apiError.find((e) => e !== null) : apiError;
  const apiErrorMessage = errorToRender && mapErrorCodeToUiMessage({ error: errorToRender });

  const customErrorMessage = apiErrorMessage ?? errorMessage;

  function handleCustomCallback() {
    customCallback?.callback() || window.location.reload();
  }

  useCaptureException(customErrorMessage, 'ErrorPage');

  return (
    <div className='mx-auto flex min-h-[60vh] max-w-2xl flex-col items-center justify-center p-4 text-center md:p-10'>
      <NoddiFeedbackBox
        variant='error'
        heading={errorTitle ?? t`We're sorry, an error has occurred`}
        description={customErrorMessage ?? t`We are working on solving the issue, please try again later`}
      />

      <NoddiButton variant='primary' onClick={handleCustomCallback} style={{ marginTop: '20px' }}>
        {customCallback?.ctaText ?? <Trans>Try again</Trans>}
      </NoddiButton>
    </div>
  );
};

export { ErrorPage };
