import { t } from '@lingui/macro';
import { NoddiButton, NoddiDialog } from 'noddi-ui';
import { formatCurrencyAmount } from 'noddi-util';
import { useState } from 'react';

import useBookingSummaryProps from '../../../../hooks/useBookingSummaryProps';
import tracking from '../../../../tracking';
import { useMembershipPrograms } from '../../hooks/useMembershipPrograms';
import PriceSummary from './PriceSummary';

const ShoppingCart = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { data: membershipData } = useMembershipPrograms();
  const { totalPrice } = useBookingSummaryProps({ membershipData });

  if (totalPrice === 0) {
    return <></>;
  }

  return (
    <>
      <NoddiDialog
        sx={{ zIndex: 1400 }}
        open={isDialogOpen}
        onClose={() => {
          setIsDialogOpen(false);
        }}
        title={t`Your shopping cart`}
        maxWidth='md'
      >
        <PriceSummary membershipData={membershipData} />
      </NoddiDialog>

      <NoddiButton
        variant='secondary'
        onClick={() => {
          tracking.track('shoppingCartOpened');
          setIsDialogOpen(true);
        }}
        startIcon='ShoppingCart'
      >
        <span>{formatCurrencyAmount(totalPrice, 0)}</span>
      </NoddiButton>
    </>
  );
};

export default ShoppingCart;
