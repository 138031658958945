import { t } from '@lingui/macro';
import { Profile as SharedProfile } from 'noddi-ui';

import HomeContentWrapper from '../../components/Layouts/HomeContentWrapper';

const Profile = () => (
  <HomeContentWrapper title={t`Your profile`}>
    <SharedProfile hideHeader />
  </HomeContentWrapper>
);

export default Profile;
