import { Trans, t } from '@lingui/macro';
import { NoddiTextInput, SMSLogin, UserRegistrationForm, useLoginRedirect } from 'noddi-ui';
import { useState } from 'react';

import routes from '../../appRoutes';
import { BasicHeaderNav } from '../../components/Layouts/BasicHeaderNav';
import HomeContentWrapper from '../../components/Layouts/HomeContentWrapper';
import tracking from '../../tracking';

const CompanySignup = () => {
  const [orgName, setOrgName] = useState('');
  const [orgNumber, setOrgNumber] = useState('');

  useLoginRedirect({ homePath: routes.companySignupSuccess.getPath() });
  return (
    <div className='min-h-screen bg-peach-to-purple'>
      <BasicHeaderNav />
      <HomeContentWrapper title={t`Noddi for you company`}>
        <p>
          <Trans>
            Do you need help with car washing, tire changes, or tire storage for the vehicles in your company?
          </Trans>
        </p>
        <p className='mt-7 font-bold text-7 text-primary-purple'>
          <Trans>Fill out the form, and we will get in touch</Trans>
        </p>

        <div className='mt-6 flex flex-col gap-6'>
          <NoddiTextInput
            value={orgName}
            onChange={(newValue) => setOrgName(newValue.target.value)}
            label={t`Company name`}
          />
          <NoddiTextInput
            value={orgNumber}
            onChange={(newValue) => setOrgNumber(newValue.target.value)}
            label={t`Company organization number`}
            type='number'
          />
        </div>

        <SMSLogin
          className='mt-4'
          userRegistrationForm={(phoneNumber) => (
            <UserRegistrationForm
              orgProperties={{ orgName, orgNumber }}
              customCTAText={t`Send in`}
              phoneNumber={phoneNumber}
              skipPasswordCreation
              trackUserIdentity={(user) => {
                tracking.trackUserIdentity(user);
                tracking.track('companySignup', { orgName, orgNumber });
              }}
            />
          )}
        />
      </HomeContentWrapper>
    </div>
  );
};

export default CompanySignup;
