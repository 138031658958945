import { cn } from 'noddi-ui/src/helpers/utilts';
import { PropsWithChildren } from 'react';

import NewAppointmentLink from '../Links/NewAppointment';

interface HomeContentWrapperProps {
  title?: string;
  subtitle?: string;
  showNewAppointmentLink?: boolean;
  widerScreen?: boolean;
  className?: string;
}

const HomeContentWrapper = ({
  children,
  title,
  showNewAppointmentLink,
  subtitle,
  widerScreen,
  className
}: PropsWithChildren<HomeContentWrapperProps>) => {
  const hasContent = !!title || !!subtitle || showNewAppointmentLink;
  return (
    <div
      className={cn(
        'mx-auto flex max-w-screen-mdx px-6 sm:px-10 flex-col pb-6 mt-8 md:mt-14',
        widerScreen && 'xl:max-w-screen-lg xl:px-6',
        className
      )}
    >
      {hasContent && (
        <div className='mb-4 flex flex-col gap-3'>
          <div className='flex items-center justify-between gap-6'>
            {title && <h1 className='text-7 text-primary-darkPurple sm:text-13'>{title}</h1>}
            <div className='hidden md:block'>{showNewAppointmentLink && <NewAppointmentLink defaultSize />} </div>
          </div>
          {subtitle && <p className='text-primary-darkPurple'>{subtitle}</p>}

          <div className='block md:hidden'>{showNewAppointmentLink && <NewAppointmentLink />} </div>
        </div>
      )}
      {children}
    </div>
  );
};

export default HomeContentWrapper;
