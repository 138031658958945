import styled from 'styled-components';

const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const ContentContainer = styled.div<{ isMobile: boolean }>`
  max-width: 680px;
  width: 100%;
  margin-left: auto;
  box-sizing: border-box;
  margin-right: auto;
  padding: 48px 20px 64px 20px;
  height: 100%;
  min-height: ${(p) => (p.isMobile ? '92vh' : '90vh')};
  margin-top: ${(p) => (p.isMobile ? '0px' : '64px')};
  display: flex;
  flex-direction: column;
`;

export { TopContainer, InnerContainer, ContentContainer };
