import { Trans } from '@lingui/macro';
import { ServiceCategoryFromAddress } from 'noddi-async/src/types';

import { AvailableServiceCategory } from './AvailableServiceCategory';
import { UnavailableServiceCategory } from './UnavailableServiceCategory';

type Props = {
  address?: string;
  serviceCategories: ServiceCategoryFromAddress[];
  unavailableServiceCategories: string[];
  navigateToNextStepWhenAvailableService?: () => void;
};

const ServiceAvailabilityInfo = ({
  address,
  serviceCategories,
  unavailableServiceCategories,
  navigateToNextStepWhenAvailableService
}: Props) => {
  if (!address) {
    return <></>;
  }

  return (
    <div className='flex flex-col gap-4 rounded-lg bg-primary-white p-4'>
      <p className='text-6 sm:text-8'>
        <Trans>Hooray!</Trans> 🎉
      </p>
      <p className='text-5 sm:text-6'>
        <Trans>You can book these services to your adress</Trans>
      </p>
      <div className='flex flex-col gap-6'>
        {serviceCategories.map((serviceCategory, index) => (
          <AvailableServiceCategory
            navigateToNextStepWhenAvailableService={navigateToNextStepWhenAvailableService}
            key={index}
            serviceCategory={serviceCategory}
          />
        ))}
      </div>
      {unavailableServiceCategories.length > 0 && (
        <div className='flex flex-col gap-4'>
          <div className='h-0.25 bg-primary-purple/90 opacity-45' />
          <p>
            <Trans>We do not offer these services at this address</Trans>
          </p>
          <div className='flex flex-col gap-6'>
            {unavailableServiceCategories.map((serviceCategoryName, index) => (
              <UnavailableServiceCategory key={index} serviceCategoryName={serviceCategoryName} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export { ServiceAvailabilityInfo };
