import { cva } from 'class-variance-authority';
import { ReactNode } from 'react';

import { colors } from '../../tailwind-design-preset';
import { IconName, NoddiIcon } from '../atoms';
import { cn } from '../helpers/utilts';
import { NoddiIconButton } from '../molecules/NoddiIconButton';
import { ChipVariants } from './types/chip';

interface NoddiChipProps {
  label: ReactNode;
  onDelete?: () => void;
  variant?: ChipVariants;
  icon?: IconName;
}

const chipVariants = cva('flex h-10 w-fit items-center justify-center gap-2 whitespace-nowrap rounded-md px-4 py-1', {
  variants: {
    variant: {
      neutral: 'border border-secondary-rapsberry/50 bg-secondary-rapsberry/20 text-primary-black',
      success: 'border border-signal-success bg-signal-success/50 text-primary-black',
      destructive: 'border border-signal-danger bg-signal-danger/50 text-primary-black',
      warning: 'border border-signal-warning bg-signal-warning50 text-primary-black'
    }
  },
  defaultVariants: {
    variant: 'neutral'
  }
});

const iconColorVariantsInNoddiButton = cva('', {
  variants: {
    variant: {
      neutral: colors.text.primary,
      success: colors.primary.black,
      destructive: colors.primary.black,
      warning: colors.primary.black
    }
  }
});

const NoddiChip = ({ label, onDelete, variant, icon }: NoddiChipProps) => {
  const iconColor = iconColorVariantsInNoddiButton({ variant });

  return (
    <div className={cn(chipVariants({ variant }))}>
      {icon && <NoddiIcon name={icon} size='medium' color={iconColor} />}
      <span>{label}</span>
      {onDelete && (
        <div
          className='ml-2 cursor-pointer rounded-full'
          onClick={(e) => {
            e.stopPropagation();
            onDelete();
          }}
        >
          <NoddiIconButton variant='secondary' className='border-0' iconName='Cross' iconSize='small' />
        </div>
      )}
    </div>
  );
};

export { NoddiChip };
