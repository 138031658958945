import { MembershipDiscountProps } from 'noddi-async/src/types';
import { formatCurrencyAmount } from 'noddi-util';

import { ActiveCoupon } from '../../../../stores/CouponStore';
import { getNewPriceForSalesItem } from './utils';

interface DiscountSectionProps {
  activeDiscountsForSalesItems: (MembershipDiscountProps & { price: number })[];
  activeCouponsForSalesItems: (ActiveCoupon & { price: number })[];
}

const renderDiscountItem = (name: string, price: number, discount?: MembershipDiscountProps, coupon?: ActiveCoupon) => {
  const discountedPrice = price - getNewPriceForSalesItem({ price, discount, coupon });
  return (
    <div className='flex flex-row justify-between gap-4'>
      <p>{name}</p>
      <p className=' text-nowrap text-signal-success'>-{formatCurrencyAmount(discountedPrice, 0)}</p>
    </div>
  );
};

const DiscountSection = ({ activeDiscountsForSalesItems, activeCouponsForSalesItems }: DiscountSectionProps) => {
  return (
    <div className='mt-2 flex w-full flex-col'>
      {activeDiscountsForSalesItems.map((discount) => renderDiscountItem(discount.name, discount.price, discount))}
      {activeCouponsForSalesItems.map((coupon) =>
        renderDiscountItem(coupon.namePublic, coupon.price, undefined, coupon)
      )}
    </div>
  );
};

export default DiscountSection;
