import { colors } from '../../../../tailwind-design-preset';
import { NoddiIcon } from '../../../atoms/NoddiIcon';
import { getServiceCategoryTranslations } from './utils';

type UnavailableServiceCategoryProps = {
  serviceCategoryName: string;
};

export const UnavailableServiceCategory = ({ serviceCategoryName }: UnavailableServiceCategoryProps) => {
  const data = getServiceCategoryTranslations({ serviceCategoryName: serviceCategoryName });
  return (
    <div className='flex gap-2'>
      <NoddiIcon name='Sad' color={colors.primary.black} size='large' />
      <span className='text-5 sm:text-6'>{data.name}</span>
    </div>
  );
};
