import {
  AvailableSalesItemForBookingItem,
  BookingItemOnRoute,
  CarTireMeasurements,
  DetailedActiveRouteItem,
  DetailedRoute,
  EstimatedRoutesPayment,
  PaginatedInput,
  PaginatedResponse,
  RouteForServiceWorker,
  RouteItemCustomerOverview,
  RouteSettlementPayment,
  SalesCar,
  ServiceWorker,
  ServiceWorkerInput,
  ServiceWorkerList,
  ServiceWorkerType,
  UnableToCompleteReason,
  WheelMeasurementWorkerApp,
  WorkItem
} from '../types';
import { FetchMethods, ResponseType, apiVersion } from './commonResourceTypes';
import { CommonAxiosResType, getNoddiUrl } from './resources';

export const WorkerURLKeys = {
  getPaginatedServiceWorkers: 'getPaginatedServiceWorkers',
  getServiceWorkers: 'getServiceWorkers',
  getServiceWorker: 'getServiceWorker',
  postServiceWorker: 'postServiceWorker',
  patchServiceWorker: 'patchServiceWorker',
  getServiceWorkerTypes: 'getServiceWorkerTypes',
  postDelayedMessage: 'postDelayedMessage',
  postSendNextArrivalSms: 'postSendNextArrivalSms',
  postSendCustomSms: 'postSendCustomSms',
  postMarkRouteItemComplete: 'postMarkRouteItemComplete',
  getRouteItemsDetailsForServiceWorker: 'getRouteItemsDetailsForServiceWorker',
  postWheelsMeasurements: 'postWheelsMeasurements',
  postAddLicensePlateNumber: 'postAddLicensePlateNumber',
  getUnableToCompleteReasons: 'getUnableToCompleteReasons',
  getCapacityContributionsForServiceWorker: 'getCapacityContributionsForServiceWorker',
  postAddBookingItemToExistingBooking: 'postAddBookingItemToExistingBooking',
  getBookingItemShouldMeasureWheels: 'getBookingItemShouldMeasureWheels',
  postAddExtraSalesItemsToBookingItem: 'postAddExtraSalesItemsToBookingItem',
  postUndoUnableToCompleteBookingItems: 'postUndoUnableToCompleteBookingItems',
  postMarkRouteItemStarted: 'postMarkRouteItemStarted',
  getRouteSettlementsForWorker: 'getRouteSettlementsForWorker',
  postStartRouteForServiceWorker: 'postStartRouteForServiceWorker',
  postConfirmRouteForServiceWorker: 'postConfirmRouteForServiceWorker',
  getRouteServiceWorker: 'getRouteServiceWorker',
  postMarkCarItemInBookingComplete: 'postMarkCarItemInBookingComplete',
  getRoutesForServiceWorker: 'getRoutesForServiceWorker',
  getRouteForServiceWorker: 'getRouteForServiceWorker',
  getEstimatedRoutePayment: 'getEstimatedRoutePayment',
  getBookingItemsForRoute: 'getBookingItemsForRoute',
  getRouteItemCustomerOverview: 'getRouteItemCustomerOverview',
  postMarkBookingItemsAsComplete: 'postMarkBookingItemsAsComplete',
  postMarkBookingAsComplete: 'postMarkBookingAsComplete',
  postAddCarTireMeasurements: 'postAddCarTireMeasurements',
  postNextCustomerArrivalSMS: 'postNextCustomerArrivalSMS',
  getAvailableSalesItemsForBookingItem: 'getAvailableSalesItemsForBookingItem',
  postTranslateText: 'postTranslateText'
} as const;

export type WorkerURLKeysType = keyof typeof WorkerURLKeys;

type InputDataOrVoid<Type extends WorkerURLKeysType> = InputData<Type> extends void ? void : InputData<Type>;

type InputData<urlKey extends WorkerURLKeysType> = WorkerServerRequests[urlKey]['input'];

export const getWorkerUrls: {
  [key in WorkerURLKeysType]: {
    getUrl: (input: InputDataOrVoid<key>) => string;
    getBody?: (input: InputDataOrVoid<key>) => Partial<InputData<key>>;
    method: FetchMethods;
    responseType?: ResponseType;
    handleRes?: (res: CommonAxiosResType<key>) => Promise<unknown>;
    getHeaders?: (input: InputDataOrVoid<key>) => Record<string, string>;
    useSuperUserToken?: boolean;
    skipConvertingToCamelCase?: boolean;
  };
} = {
  getPaginatedServiceWorkers: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}service-workers/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getServiceWorkers: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}service-workers/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getServiceWorker: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}service-workers/${id}`
      }),
    method: FetchMethods.get
  },
  postServiceWorker: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}service-workers/`
      }),
    getBody: (ServiceWorkerInput) => ServiceWorkerInput,
    method: FetchMethods.post
  },
  patchServiceWorker: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}service-workers/${id}`
      }),
    getBody: (ServiceWorkerInput) => ServiceWorkerInput,
    method: FetchMethods.patch
  },
  getServiceWorkerTypes: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}service-workers/types/`
      }),
    method: FetchMethods.get
  },
  postDelayedMessage: {
    getUrl({ routeId, routeItemId }) {
      return getNoddiUrl({
        path: `${apiVersion}routes/${routeId}/route-items/${routeItemId}/send-next-arrival-sms/`
      });
    },
    getBody: ({ numMinutes }) => ({ numMinutes }),
    method: FetchMethods.post
  },
  postSendNextArrivalSms: {
    getUrl({ routeId, routeItemId }) {
      return getNoddiUrl({
        path: `${apiVersion}routes/${routeId}/route-items/${routeItemId}/send-next-arrival-sms/`
      });
    },
    getBody: ({ numMinutes }) => ({ numMinutes }),
    method: FetchMethods.post
  },
  postSendCustomSms: {
    getUrl({ routeId, routeItemId }) {
      return getNoddiUrl({
        path: `${apiVersion}routes/${routeId}/route-items/${routeItemId}/send-custom-sms/`
      });
    },
    getBody: ({ message }) => ({ message }),
    method: FetchMethods.post
  },
  postMarkRouteItemComplete: {
    getUrl({ bookingId }) {
      return getNoddiUrl({
        path: `${apiVersion}bookings/${bookingId}/mark-as-complete/`
      });
    },
    getBody: ({ commentsWorker, notifyCustomer }) => ({ commentsWorker, notifyCustomer }),
    method: FetchMethods.post
  },
  getRouteItemsDetailsForServiceWorker: {
    getUrl: ({ routeId, routeItemId }) =>
      getNoddiUrl({
        path: `${apiVersion}routes/${routeId}/route-items/${routeItemId}/for-service-worker/`
      }),
    method: FetchMethods.get
  },
  postWheelsMeasurements: {
    getUrl() {
      return getNoddiUrl({
        path: `${apiVersion}car-wheel-measurements/`
      });
    },
    getBody: (data) => data,
    method: FetchMethods.post
  },
  getCapacityContributionsForServiceWorker: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}capacity-contributions/for-service-worker/`
      }),
    method: FetchMethods.get
  },
  postAddLicensePlateNumber: {
    getUrl({ carId }) {
      return getNoddiUrl({
        path: `${apiVersion}cars/${carId}/update-with-license-plate-number/`
      });
    },
    getBody: (data) => data,
    method: FetchMethods.post
  },
  getUnableToCompleteReasons: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}bookings/unable-to-complete-reasons/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  postAddBookingItemToExistingBooking: {
    getUrl({ bookingId }) {
      return getNoddiUrl({
        path: `${apiVersion}bookings/${bookingId}/add-booking-item-and-extra-sales-items/`
      });
    },
    getBody: (data) => data,
    method: FetchMethods.post
  },
  getBookingItemShouldMeasureWheels: {
    getUrl({ bookingItemId }) {
      return getNoddiUrl({
        path: `${apiVersion}booking-items/${bookingItemId}/should-measure-wheels/`
      });
    },
    method: FetchMethods.get
  },
  postAddExtraSalesItemsToBookingItem: {
    getUrl({ id }) {
      return getNoddiUrl({
        path: `${apiVersion}booking-items/${id}/add-extra-sales-items/`
      });
    },
    getBody: (data) => data,
    method: FetchMethods.post
  },
  postUndoUnableToCompleteBookingItems: {
    getUrl({ routeId, routeItemId }) {
      return getNoddiUrl({
        path: `${apiVersion}routes/${routeId}/route-items/${routeItemId}/undo-booking-item-as-unable-to-complete/`
      });
    },
    getBody: (data) => data,
    method: FetchMethods.post
  },
  postMarkRouteItemStarted: {
    getUrl({ routeId, routeItemId }) {
      return getNoddiUrl({
        path: `${apiVersion}routes/${routeId}/route-items/${routeItemId}/mark-route-item-started/`
      });
    },
    method: FetchMethods.post
  },
  getRouteSettlementsForWorker: {
    getUrl: ({ userId, ...queryParams }) =>
      getNoddiUrl({
        path: `${apiVersion}route-settlements/for-worker/${userId}`,
        queryParams
      }),
    method: FetchMethods.get
  },
  postStartRouteForServiceWorker: {
    getUrl({ routeId }) {
      return getNoddiUrl({
        path: `${apiVersion}routes/${routeId}/start-route-for-service-worker/`
      });
    },
    getBody: (data) => data,
    method: FetchMethods.post
  },
  postConfirmRouteForServiceWorker: {
    getUrl({ routeId }) {
      return getNoddiUrl({
        path: `${apiVersion}routes/${routeId}/confirm-route-for-service-worker/`
      });
    },
    getBody: (data) => data,
    method: FetchMethods.post
  },
  getRouteServiceWorker: {
    getUrl: ({ routeId }) =>
      getNoddiUrl({
        path: `${apiVersion}routes/${routeId}/for-service-worker/`
      }),
    method: FetchMethods.get
  },
  postMarkCarItemInBookingComplete: {
    getUrl({ routeId, routeItemId }) {
      return getNoddiUrl({
        path: `${apiVersion}routes/${routeId}/route-items/${routeItemId}/mark-booking-item-complete/`
      });
    },
    getBody: (data) => data,
    method: FetchMethods.post
  },
  getRoutesForServiceWorker: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}routes/for-service-worker-v2/`
      }),
    method: FetchMethods.get
  },
  getEstimatedRoutePayment: {
    getUrl: ({ routeId }) =>
      getNoddiUrl({
        path: `${apiVersion}routes/${routeId}/estimated-payment/`
      }),
    method: FetchMethods.get
  },
  getBookingItemsForRoute: {
    getUrl: ({ routeItemId }) =>
      getNoddiUrl({
        path: `${apiVersion}route-items/${routeItemId}/booking-items/`
      }),
    method: FetchMethods.get
  },
  getRouteForServiceWorker: {
    getUrl: ({ routeId }) =>
      getNoddiUrl({
        path: `${apiVersion}routes/${routeId}/for-service-worker-v2/`
      }),
    method: FetchMethods.get
  },
  getRouteItemCustomerOverview: {
    getUrl: ({ routeItemId }) =>
      getNoddiUrl({
        path: `${apiVersion}route-items/${routeItemId}/customer-overview/`
      }),
    method: FetchMethods.get
  },
  postMarkBookingItemsAsComplete: {
    getUrl: ({ bookingItemId }) =>
      getNoddiUrl({
        path: `${apiVersion}booking-items/${bookingItemId}/mark-as-complete/`
      }),
    getBody: (data) => data,
    method: FetchMethods.post
  },
  postMarkBookingAsComplete: {
    getUrl: ({ bookingId }) =>
      getNoddiUrl({
        path: `${apiVersion}bookings/${bookingId}/mark-as-complete/`
      }),
    method: FetchMethods.post
  },
  postAddCarTireMeasurements: {
    getUrl: ({ carId }) =>
      getNoddiUrl({
        path: `${apiVersion}cars/${carId}/add-measurements/`
      }),
    getBody: (data) => data,
    method: FetchMethods.post
  },
  postNextCustomerArrivalSMS: {
    getUrl: ({ routeItemId }) =>
      getNoddiUrl({
        path: `${apiVersion}route-items/${routeItemId}/send-next-arrival-sms/`
      }),
    getBody: (data) => data,
    method: FetchMethods.post
  },
  getAvailableSalesItemsForBookingItem: {
    getUrl: ({ bookingItemId, ...queryParams }) =>
      getNoddiUrl({
        path: `${apiVersion}booking-items/${bookingItemId}/available-sales-items/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  postTranslateText: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}core/translate-text/`
      }),
    getBody: (data) => data,
    method: FetchMethods.post
  }
} as const;

export interface WorkerServerRequests {
  getPaginatedServiceWorkers: {
    input: PaginatedInput;
    output: PaginatedResponse<ServiceWorkerList[]>;
  };
  getServiceWorkers: {
    input: { search?: string };
    output: ServiceWorkerList[];
  };
  getServiceWorker: {
    input: { id: number | string | undefined | null };
    output: ServiceWorker;
  };
  postServiceWorker: {
    input: ServiceWorkerInput;
    output: ServiceWorker;
  };
  patchServiceWorker: {
    input: ServiceWorkerInput & { id: number | string | undefined | null };
    output: ServiceWorker;
  };
  getServiceWorkerTypes: {
    input: undefined;
    output: ServiceWorkerType[];
  };
  postDelayedMessage: {
    input: {
      routeId: string;
      routeItemId: number;
      numMinutes: number | undefined;
    };
    output: undefined;
  };
  postSendNextArrivalSms: {
    input: {
      routeId: string;
      routeItemId: number;
      numMinutes: number | undefined;
    };
    output: undefined;
  };
  postSendCustomSms: {
    input: {
      routeId: string;
      routeItemId: number;
      message: string;
    };
    output: undefined;
  };
  postMarkRouteItemComplete: {
    input: {
      bookingId: number;
      commentsWorker?: string;
      notifyCustomer?: boolean;
    };
    output: undefined;
  };
  getRouteItemsDetailsForServiceWorker: {
    input: {
      routeId: string;
      routeItemId: string;
    };
    output: DetailedActiveRouteItem;
  };
  postWheelsMeasurements: {
    input: {
      measurements: WheelMeasurementWorkerApp[];
      bookingItemId?: number;
    };
    output: unknown;
  };
  getCapacityContributionsForServiceWorker: {
    input: undefined;
    output: WorkItem[];
  };
  postAddLicensePlateNumber: {
    input: {
      carId: number;
      licensePlateNumber: string;
    };
    output: undefined;
  };
  getUnableToCompleteReasons: {
    input: {
      isForBooking?: boolean;
      salesItemIds?: number[];
    };
    output: UnableToCompleteReason[];
  };
  postAddBookingItemToExistingBooking: {
    input: {
      bookingId: number;
      car: SalesCar | null;
      salesItemIds: number[];
    };
    output: undefined;
  };
  getBookingItemShouldMeasureWheels: {
    input: {
      bookingItemId: number;
    };
    output: boolean;
  };
  postAddExtraSalesItemsToBookingItem: {
    input: {
      id: number;
      salesItemIds: number[];
    };
    output: undefined;
  };
  postUndoUnableToCompleteBookingItems: {
    input: {
      routeId: string;
      routeItemId: string;
      bookingItemSalesItemIds: number[];
    };
    output: undefined;
  };
  postMarkRouteItemStarted: {
    input: {
      routeId: number;
      routeItemId: number;
    };
    output: undefined;
  };
  getRouteSettlementsForWorker: {
    input: PaginatedInput & { userId: string; type: number };
    output: PaginatedResponse<RouteSettlementPayment[]>;
  };
  postStartRouteForServiceWorker: {
    input: {
      routeId: number;
    };
    output: undefined;
  };
  postConfirmRouteForServiceWorker: {
    input: {
      routeId: number;
    };
    output: undefined;
  };
  getRouteServiceWorker: {
    input: {
      routeId: string;
    };
    output: DetailedRoute;
  };
  postMarkCarItemInBookingComplete: {
    input: {
      routeId: string;
      routeItemId: string;
      bookingItemId: number;
      skipCarWheelMeasurementsReason?: string;
    };
    output: undefined;
  };
  getRoutesForServiceWorker: {
    input: undefined;
    output: RouteForServiceWorker[];
  };
  getRouteForServiceWorker: {
    input: { routeId: number };
    output: RouteForServiceWorker;
  };
  getEstimatedRoutePayment: {
    input: { routeId: number };
    output: EstimatedRoutesPayment;
  };
  getBookingItemsForRoute: {
    input: { routeItemId: number };
    output: BookingItemOnRoute[];
  };
  getRouteItemCustomerOverview: {
    input: { routeItemId: number };
    output: RouteItemCustomerOverview;
  };
  postMarkBookingItemsAsComplete: {
    input: { bookingItemId: number; skipCarWheelMeasurementsReason?: string | null };
    output: undefined;
  };
  postMarkBookingAsComplete: {
    input: { bookingId: number };
    output: undefined;
  };
  postAddCarTireMeasurements: {
    input: { carId: number; measurements: CarTireMeasurements };
    output: undefined;
  };
  postNextCustomerArrivalSMS: {
    input: { routeItemId: number; numMinutes: number };
    output: undefined;
  };
  getAvailableSalesItemsForBookingItem: {
    input: { bookingItemId: number; type?: 'extra' | null };
    output: AvailableSalesItemForBookingItem[];
  };
  postTranslateText: {
    input: { text: string; targetLanguage: string; sourceLanguage?: string };
    output: { data: { translatedText: string } };
  };
}
