import { Trans } from '@lingui/macro';
import { NoddiButton, NoddiIconButton, NoddiLogo, colors } from 'noddi-ui';
import { useLocation, useNavigate } from 'react-router-dom';

import routes from '../../appRoutes';
import NavWrapper from './NavWrapper';

interface HomeHeaderNavProps {
  onNavOpen: () => void;
}

export const HomeHeaderNav = ({ onNavOpen }: HomeHeaderNavProps) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const pathToRegex = (path: string) => new RegExp(`^${path.replace(/:\w+/g, '\\w+')}$`);

  //Check if the current path is a base path of any route and has isBaseRouterPath set to true
  const isBaseRouterPath = Object.values(routes).some((route) => {
    const basePath = route.getBasePath();
    const regex = pathToRegex(`/home${basePath}`);
    return (regex.test(pathname) && route.isBaseRouterPath) || pathname === '/homepage';
  });

  return (
    <NavWrapper>
      <div className='flex w-full items-center gap-6'>
        {!isBaseRouterPath && (
          <NoddiIconButton
            iconName='ArrowLeft'
            iconSize='medium'
            onClick={() => {
              navigate(-1);
            }}
            variant='secondary'
            className='border-0'
          />
        )}
        {isBaseRouterPath && (
          <button onClick={() => navigate(routes.homepage.getPath())}>
            <NoddiLogo color={colors.primary.orange} width={67} height={19} />
          </button>
        )}

        <div className='ml-auto flex gap-3 '>
          <NoddiButton variant='secondary' className='border-none' endIcon='HamburgerMenu' onClick={onNavOpen}>
            <Trans>Menu</Trans>
          </NoddiButton>
        </div>
      </div>
    </NavWrapper>
  );
};
