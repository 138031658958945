import {
  CreateBookingParams,
  MembershipProgramProps,
  SelectedWheelStorageOption,
  UserDataProps
} from 'noddi-async/src/types';
import { useAuthContext } from 'noddi-provider';

import { useBookingContext } from '../contexts/BookingContext';
import { getBookingDiscountProps } from '../helper/salesItems';
import { BookingInputFormData, SelectedCar, SelectedSalesItemFull } from '../pages/BookingFlow/interfaces';
import { ActiveCoupon, useCouponStore } from '../stores/CouponStore';
import { useWheelStore } from '../stores/WheelStore';

export interface BuildBookingParams {
  inputData: BookingInputFormData;
  userGroupId: number | null | undefined;
  coupons: ActiveCoupon[];
  membershipPrograms: MembershipProgramProps[] | undefined;
  userData: UserDataProps | null;
  salesItems: SelectedSalesItemFull[];
  wheelStorage?: Required<SelectedWheelStorageOption>[];
}

type BuildSalesItemsParams = {
  cars: SelectedCar[];
  membershipPrograms: MembershipProgramProps[] | undefined;
  coupons: ActiveCoupon[];
  selectedSalesItems: SelectedSalesItemFull[];
};

const buildSalesItemsDTO = ({ cars, membershipPrograms, coupons, selectedSalesItems }: BuildSalesItemsParams) => {
  return selectedSalesItems.map((salesItem) => {
    const car = cars.find((car) => car.licensePlateNumber === salesItem.licensePlateNumber);
    if (!car) {
      throw new Error('Could not find car for sales item');
    }

    const { couponDiscounts, membershipDiscount } = getBookingDiscountProps({ coupons, membershipPrograms, salesItem });

    return {
      licensePlateNumber: car.licensePlateNumber,
      id: salesItem.id,
      quantity: salesItem.quantity,
      serviceTime: salesItem.serviceTime,
      couponDiscounts,
      membershipDiscount
    };
  });
};

const buildBookingParams = ({
  inputData,
  userGroupId,
  membershipPrograms,
  coupons,
  userData,
  salesItems,
  wheelStorage
}: BuildBookingParams) => {
  const { address, time } = inputData;
  if (!address) {
    throw new Error(`Missing address in booking summary, got address: ${JSON.stringify(address)}`);
  }
  if (!time) {
    throw new Error(`Missing time in booking summary, got time: ${JSON.stringify(time)}`);
  }
  if (!userData) {
    throw new Error(`Missing userData in booking summary, got userData: ${JSON.stringify(userData)}`);
  }

  if (!userGroupId) {
    throw new Error('Missing user group id');
  }
  const salesItemsDto = buildSalesItemsDTO({
    cars: inputData.selectedCars,
    membershipPrograms,
    coupons,
    selectedSalesItems: salesItems
  });

  const ret = {
    address: address,
    userId: userData.user.id,
    bookingTimeWindowId: time.id,
    comments: inputData.comment,
    salesItems: salesItemsDto,
    userGroupId: userGroupId,
    wheelStorage
  };

  if (!wheelStorage) {
    delete ret.wheelStorage;
  }

  return ret;
};

const useBookingPayload = () => {
  const { currentUserGroupId: userGroupId, userData } = useAuthContext();
  const { salesItems } = useBookingContext();
  const { coupons } = useCouponStore();
  const { selectedWheelStorageOptions } = useWheelStore();
  const { bookingInputData: inputData } = useBookingContext();

  const createBookingPayload = (membershipPrograms: MembershipProgramProps[] | undefined): CreateBookingParams => {
    return buildBookingParams({
      inputData,
      userGroupId,
      membershipPrograms,
      coupons,
      salesItems,
      userData,
      wheelStorage: selectedWheelStorageOptions as Required<SelectedWheelStorageOption>[]
    });
  };

  const getTrackingData = () => {
    return {
      salesItems,
      coupons,
      time: inputData.time,
      cars: inputData.selectedCars
    };
  };

  return { createBookingPayload, getTrackingData };
};

export default useBookingPayload;
