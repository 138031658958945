import { Trans } from '@lingui/macro';
import { useAuthContext, useLanguageContext } from 'noddi-provider';
import { NoddiButton, NoddiIconButton, NoddiLanguageSelector, NoddiLogo } from 'noddi-ui';
import { useLocation, useNavigate } from 'react-router-dom';

import { getSupportedLocales } from '../../App';
import routes from '../../appRoutes';
import tracking from '../../tracking';
import NavWrapper from './NavWrapper';

export const CustomerHeaderNav = () => {
  const { isLoggedIn } = useAuthContext();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const isLoginPage = pathname === routes.login.getPath();
  const { currentLanguage, setCurrentLanguage } = useLanguageContext();
  const isAddressStep = pathname.includes(routes.newBookingAddressStep.getBasePath());

  const isSummaryStep = pathname.includes(routes.newBookingSummaryStep.getBasePath());

  const goToLogin = () => {
    const redirectUrl = window.location.pathname !== routes.login.getPath() ? window.location.pathname : undefined;
    const extraParams = redirectUrl ? `?redirectUrl=${redirectUrl}` : '';
    navigate(`${routes.login.getPath()}${extraParams}`);
  };

  const goToNewBooking = () => {
    navigate(routes.newBookingAddressStep.getPath());
  };

  const CreateBookingButton = () => (
    <NoddiButton variant='ghost' onClick={goToNewBooking} endIcon='ArrowRight'>
      <Trans>Create booking</Trans>
    </NoddiButton>
  );

  const MyProfileButton = () => (
    <NoddiButton variant='ghost' onClick={() => navigate(routes.homepage.getPath())} endIcon='UserCircle'>
      <Trans>My profile</Trans>
    </NoddiButton>
  );

  const SignInButton = () => (
    <NoddiButton variant='ghost' onClick={goToLogin} endIcon='UserCircle'>
      <Trans>Sign in</Trans>
    </NoddiButton>
  );

  const showBackButton = !isLoginPage && !isAddressStep;

  return (
    <NavWrapper>
      <div>
        <div className='flex w-full items-center gap-6'>
          {showBackButton && (
            <NoddiIconButton
              iconName='ArrowLeft'
              iconSize='medium'
              onClick={() => {
                navigate(-1);
              }}
              variant='secondary'
              className='border-0'
            />
          )}

          {isAddressStep && (
            <button onClick={() => navigate(routes.homepage.getPath())} className=' hidden sm:block'>
              <NoddiLogo width={67} height={19} />
            </button>
          )}

          <NoddiLanguageSelector
            supportedLanguages={getSupportedLocales()}
            currentLanguage={currentLanguage}
            setCurrentLanguage={(locale) => {
              tracking.track('languageChanged', { language: locale });
              setCurrentLanguage(locale);
            }}
          />

          {!isSummaryStep && (
            <div className='ml-auto flex gap-3 '>
              {isLoginPage ? <CreateBookingButton /> : <>{isLoggedIn ? <MyProfileButton /> : <SignInButton />}</>}
            </div>
          )}
        </div>
      </div>
    </NavWrapper>
  );
};
