import { colors } from '../../tailwind-design-preset';
import { NoddiIcon } from '../atoms';

export const IconBasedOnSalesItem = ({ categorySlug }: { categorySlug?: string }) => {
  switch (categorySlug) {
    case 'wheel_services':
      return <NoddiIcon name='WheelAngle' size='medium' color={colors.primary.black} />;
    case 'wash_services':
      return <NoddiIcon name='Drop' size='medium' color={colors.primary.black} />;
    case 'packages':
      return <NoddiIcon name='Heart' size='medium' color={colors.primary.black} />;
    default:
      return <NoddiIcon name='WheelAngle' size='medium' color={colors.primary.black} />;
  }
};
