import { useBookingContext } from '../../../contexts/BookingContext';
import { IncompatibleServiceCategoryProps } from '../Steps/SalesItems/interface';
import { SelectedSalesItem } from '../interfaces';

const useUpdateSalesItemIncompatibles = () => {
  const {
    bookingInputData,
    setIncompatibleServiceCategories,
    updateBookingInputData,
    salesItems,
    getAllAvailableSalesItemsOrUndefined
  } = useBookingContext();

  const resolveServiceAreaId = (allSelectedServiceCategoryIds: number[]) => {
    const allSalesItemIds = salesItems.map(({ id }) => id);
    const sortedAllSelectedServiceCategoryIds = allSelectedServiceCategoryIds.sort();

    const allSalesItemIdsSet = new Set(allSalesItemIds);
    const sortedServiceCategoryIdsString = JSON.stringify(sortedAllSelectedServiceCategoryIds);

    const serviceArea = bookingInputData.serviceAreas.find((serviceArea) => {
      const isServiceAreaSuitableForAllSalesItems = Array.from(allSalesItemIdsSet.values()).every((id) =>
        serviceArea.salesItemIds.includes(id)
      );

      if (!isServiceAreaSuitableForAllSalesItems) {
        return false;
      }

      return serviceArea.licenseCategories.some((categories) => {
        const serviceCategoryIds = categories.map((category) => category.id);

        return JSON.stringify(serviceCategoryIds.sort()) === sortedServiceCategoryIdsString;
      });
    });

    return serviceArea?.id ?? null;
  };

  const updateSalesItemIncompatibles = async (selectedSalesItems: SelectedSalesItem[]) => {
    const allSalesItems = getAllAvailableSalesItemsOrUndefined();
    const salesItems = allSalesItems?.filter((item) => selectedSalesItems.map((i) => i.id).includes(item.id)) ?? [];

    // Collect all distinct selected service category ids
    const allSelectedServiceCategoryIds = [
      ...new Set(
        salesItems
          .filter((salesItem) => selectedSalesItems.map((item) => item.id).includes(salesItem.id))
          .map((item) => item.serviceCategory.id)
      )
    ];

    // Get the compatible service areas based on the selected service category ids
    const newCompatibleServiceCategoryIds: number[] = [];
    bookingInputData.serviceAreas.forEach((serviceArea) => {
      serviceArea.licenseCategories.forEach((lc) => {
        const serviceCategoryIds = lc.map((sc) => sc.id);
        if (allSelectedServiceCategoryIds.every((sc) => serviceCategoryIds.includes(sc))) {
          serviceCategoryIds.forEach((scId) => {
            if (!newCompatibleServiceCategoryIds.includes(scId)) {
              newCompatibleServiceCategoryIds.push(scId);
            }
          });
        }
      });
    });

    // Get the incompatible service categories
    const incompatibleServiceCategories: IncompatibleServiceCategoryProps[] = [];
    bookingInputData.serviceCategories.forEach((sc) => {
      // Skip already compatible service categories
      if (newCompatibleServiceCategoryIds.includes(sc.id)) {
        return;
      }
      // Get the other service categories which the current service category is compatible with
      const serviceCategoryCompatibleWith: number[] = [];
      bookingInputData.serviceAreas.forEach((serviceArea) => {
        serviceArea.licenseCategories.forEach((lc) => {
          const serviceCategoryIds = lc.map((sc) => sc.id);
          if (serviceCategoryIds.includes(sc.id)) {
            serviceCategoryIds.forEach((id) => {
              if (!serviceCategoryCompatibleWith.includes(id)) {
                serviceCategoryCompatibleWith.push(id);
              }
            });
          }
        });
      });
      const serviceCategoryCompatibleWithWithoutSc = serviceCategoryCompatibleWith.filter((id) => id !== sc.id);
      // Find the service categories which the current service category is NOT compatible with
      const serviceCategoryIncompatibleWith: number[] = [];
      bookingInputData.serviceAreas.forEach((serviceArea) => {
        serviceArea.licenseCategories.forEach((lc) => {
          const serviceCategoryIds = lc.map((sc) => sc.id);
          serviceCategoryIds.forEach((serviceCategoryId) => {
            if (serviceCategoryCompatibleWithWithoutSc.includes(serviceCategoryId) || serviceCategoryId == sc.id) {
              return;
            }
            if (!serviceCategoryIncompatibleWith.includes(serviceCategoryId)) {
              serviceCategoryIncompatibleWith.push(serviceCategoryId);
            }
          });
        });
      });
      incompatibleServiceCategories.push({
        serviceCategoryId: sc.id,
        incompatibleCategoriesIds: serviceCategoryIncompatibleWith
      });
    });

    setIncompatibleServiceCategories(incompatibleServiceCategories);
    updateBookingInputData({
      serviceAreaId: resolveServiceAreaId(allSelectedServiceCategoryIds)
    });
  };

  return { updateSalesItemIncompatibles };
};

export default useUpdateSalesItemIncompatibles;
