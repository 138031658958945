import { NoddiFeedbackBox, NoddiFeedbackBoxSize, NoddiFeedbackWrapper } from 'noddi-ui';
import { PropsWithChildren, createContext, useCallback, useContext, useState } from 'react';

type ToastType = 'success' | 'error' | 'warning';

interface Toast {
  id: number;
  type: ToastType;
  heading: string;
  description?: string;
  size?: NoddiFeedbackBoxSize;
}

interface ToastContextType {
  addToast: (type: ToastType, message: string, description?: string, size?: NoddiFeedbackBoxSize) => void;
}

interface ToastProviderProps {
  defaultSize?: NoddiFeedbackBoxSize;
}

const ToastContext = createContext<ToastContextType | undefined>(undefined);

const ToastProvider = ({ children, defaultSize = 'default' }: PropsWithChildren<ToastProviderProps>) => {
  const [toast, setToast] = useState<Toast | null>(null);

  const addToast = useCallback(
    (type: ToastType, heading: string, description?: string, size?: NoddiFeedbackBoxSize) => {
      const id = Date.now();
      setToast({ id, type, heading, description, size: size ?? defaultSize });
      setTimeout(() => {
        setToast(null);
      }, 5000);
    },
    [defaultSize]
  );

  const { id, type, heading, description, size } = toast || {};

  return (
    <ToastContext.Provider value={{ addToast }}>
      {children}
      <NoddiFeedbackWrapper>
        <>
          {toast && (
            <NoddiFeedbackBox
              size={size}
              key={id}
              variant={type}
              heading={heading || ''}
              description={description}
              onClose={() => {
                setToast(null);
              }}
            />
          )}
        </>
      </NoddiFeedbackWrapper>
    </ToastContext.Provider>
  );
};

const useToast = (): ToastContextType => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error('useToast must be used within a ToastProvider');
  }
  return context;
};

export { ToastProvider, useToast };
