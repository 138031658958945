import { Trans } from '@lingui/macro';
import { URLKeys, noddiAsync } from 'noddi-async';
import { useAuthContext } from 'noddi-provider';
import {
  AddressPicker,
  NoddiButton,
  NoddiIcon,
  NoddiIconButton,
  NoddiLinearProgressLoader,
  SelectedAddressDetails,
  colors,
  getLongAddressName
} from 'noddi-ui';
import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { useBookingContext } from '../../contexts/BookingContext';
import useOnNextButtonClick from '../../pages/BookingFlow/Steps/Address/useOnNextButtonClick';
import { GoogleTrackingService } from '../../services/GoogleTrackingService';
import tracking from '../../tracking';
import { GtmEvents } from '../../types/gtmTracking';

interface AddressPickerComponentProps {
  hideServiceInfoBox?: boolean;
}

const AddressPickerComponent = ({ hideServiceInfoBox }: AddressPickerComponentProps) => {
  const { currentUserGroupId: userGroupId, isLoggedIn } = useAuthContext();
  const { bookingInputData, updateBookingInputData } = useBookingContext();
  const { navigateToNextStep } = useOnNextButtonClick();
  const { address } = bookingInputData;

  const {
    isLoading: isServiceAreasLoading,
    isError: isServiceAreaError,
    refetch: refetchServiceAreas,
    data: serviceAreas
  } = noddiAsync.useGet({
    type: URLKeys.getServiceAreasFromCoordinate,
    input: { lat: address?.latitude, lng: address?.longitude },
    queryConfig: {
      enabled: Boolean(address),
      staleTime: Infinity
    }
  });

  const { isLoading: userGroupAddressesIsLoading, data: userGroupAddresses } = noddiAsync.useGet({
    type: URLKeys.getUserGroupAddresses,
    input: { userGroupId: userGroupId as number },
    queryConfig: { enabled: !!userGroupId, staleTime: Infinity }
  });

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const placeIdFromUrl = searchParams.get('address');
  const showProgressLoader = isServiceAreasLoading || userGroupAddressesIsLoading;

  const hasServiceCategories = Boolean(serviceAreas?.serviceCategories?.length ?? 0 > 0);

  useEffect(() => {
    if (address) {
      refetchServiceAreas();
    }
  }, [address]);

  // each time the address changes, we want to update the serviceAreas and serviceCategories
  // that the user can choose from later in the booking flow
  useEffect(() => {
    if (serviceAreas) {
      updateBookingInputData({
        serviceAreas: serviceAreas?.serviceAreas,
        serviceCategories: serviceAreas?.serviceCategories ?? []
      });
    }

    if (serviceAreas && !hasServiceCategories) {
      tracking.track('noServiceOffered', {
        address
      });

      GoogleTrackingService.trackEvent({
        eventType: GtmEvents.noServiceOffered
      });
    }
  }, [serviceAreas]);

  const addressDropdownOptions = useMemo(() => {
    if (!userGroupAddresses) {
      return [];
    }

    const mappedAddresses = userGroupAddresses.map((item) => item.address);

    return mappedAddresses;
  }, [userGroupAddresses, address]);

  const pickedFullAddressName = address?.fullAddress?.replaceAll('undefined', '');

  return (
    <>
      {pickedFullAddressName ? (
        <div className='flex w-full items-center justify-between rounded-lg bg-primary-white p-3 pl-2'>
          <div className='mr-2 flex items-center'>
            <NoddiIcon name='LocationPin' size='large' />
            <p>{pickedFullAddressName}</p>
          </div>

          <NoddiIconButton
            iconName='Cross'
            iconSize='medium'
            variant='destructive'
            onClick={() => {
              updateBookingInputData({ address: null });
            }}
          />
        </div>
      ) : (
        <AddressPicker
          hideBottomOptions={true}
          addresses={[]}
          onSelectAddress={(address) => {
            if (!address) {
              return;
            }
            const updatedAddress = { ...address };
            updateBookingInputData({ address: updatedAddress });
          }}
          selectedAddress={address}
          placeIdFromUrl={placeIdFromUrl}
          initWithCustomSearch={!isLoggedIn}
        />
      )}

      {addressDropdownOptions.length > 0 && !pickedFullAddressName && (
        <div className='mt-4 flex flex-col gap-4'>
          <p>
            <Trans>Saved adresses</Trans>
          </p>
          {addressDropdownOptions.map((ad) => (
            <div
              key={ad.fullAddress}
              className='flex items-center justify-between gap-4 rounded-lg bg-primary-white p-4'
            >
              <div className='flex gap-2'>
                <NoddiIcon name='Home' color={colors.primary.black} />
                <div className='flex flex-col'>
                  <p>{getLongAddressName(ad)}</p>
                </div>
              </div>
              <div className='flex items-center'>
                <NoddiButton
                  variant='secondary'
                  size='small'
                  onClick={() => {
                    const updatedAddress = { ...ad };
                    updateBookingInputData({ address: updatedAddress });
                  }}
                >
                  <Trans>Add</Trans>
                </NoddiButton>
              </div>
            </div>
          ))}
        </div>
      )}

      {showProgressLoader ? (
        <NoddiLinearProgressLoader />
      ) : (
        <SelectedAddressDetails
          hideServiceInfoBox={hideServiceInfoBox}
          isServiceAreaError={isServiceAreaError}
          serviceAreas={serviceAreas}
          hasServiceCategories={hasServiceCategories}
          address={address}
          navigateToNextStepWhenAvailableService={navigateToNextStep}
        />
      )}
    </>
  );
};

export default AddressPickerComponent;
