import { Trans, t } from '@lingui/macro';
import { TextField } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { DEFAULT_LICENSE_PLATE_COUNTRY_CODE, URLKeys, noddiAsync } from 'noddi-async';
import { LicensePlateNumberData, RegisteredCar } from 'noddi-async/src/types';
import { useEffect, useRef, useState } from 'react';

import { NoddiIcon } from '../../../atoms/NoddiIcon';
import { NoddiButton } from '../../Elements';
import { ErrorMessage, mapErrorCodeToUiMessage } from '../../Elements/Errors/ErrorMessage';
import { isLicensePlateNumberAlreadyPicked } from './utils/isLicensePlateNumberAlreadyPicked';
import { isValidLicensePlateNumber } from './utils/isValidLicensePlateNumber';

type Props = {
  onAddCar: (carProps: LicensePlateNumberData) => void;
  alreadyPickedLicensePlateNumbers?: string[];
  registeredCars?: RegisteredCar[];
};
const RegNumberSearch = ({ onAddCar, alreadyPickedLicensePlateNumbers = [], registeredCars = [] }: Props) => {
  const [regNumber, setRegNumber] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const { error, data, refetch, isLoading } = noddiAsync.useGet({
    type: URLKeys.getDataFromLicensePlateNumber,
    input: { licensePlateNumber: regNumber, countryCode: DEFAULT_LICENSE_PLATE_COUNTRY_CODE },
    queryConfig: {
      enabled: false,
      staleTime: Infinity
    }
  });

  const licensePlateNumberAlreadyPicked = isLicensePlateNumberAlreadyPicked(
    alreadyPickedLicensePlateNumbers,
    regNumber,
    registeredCars
  );

  useEffect(() => {
    if (data) {
      onAddCar(data);
      setRegNumber('');
    }
  }, [data]);

  const searchInputDisabled = regNumber.length !== 7 || licensePlateNumberAlreadyPicked;

  const handleRegNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value.toUpperCase();

    if (isValidLicensePlateNumber(input)) {
      setRegNumber(input);
    }
  };

  return (
    <>
      <div className='mb-6 flex w-full items-center gap-3'>
        <TextField
          inputRef={inputRef}
          label={t`E.g. AB12345`}
          placeholder='AB12345'
          fullWidth={true}
          value={regNumber}
          onChange={handleRegNumberChange}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              !searchInputDisabled && refetch();
            }
          }}
          inputProps={{
            maxLength: 7
          }}
          InputLabelProps={{
            sx: {
              ml: '40px',
              transition: 'all 0.2s ease-in-out'
            },
            shrink: isFocused || regNumber.length > 0 // Shrink label when focused or when there's input
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start' className='relative bottom-[-3px] !mt-0'>
                <NoddiIcon name='Notes' size='large' />
              </InputAdornment>
            )
          }}
        />

        <NoddiButton
          className='whitespace-nowrap'
          variant='secondary'
          startIcon='Plus'
          onClick={() => {
            refetch();
          }}
          loading={isLoading}
          disabled={searchInputDisabled}
        >
          <Trans>Add</Trans>
        </NoddiButton>
      </div>

      {error && (
        <div className='mb-5'>
          <ErrorMessage message={`${mapErrorCodeToUiMessage({ error })}: ${regNumber}`} />
        </div>
      )}
    </>
  );
};

export { RegNumberSearch };
