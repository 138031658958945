import { Trans } from '@lingui/macro';
import { Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { NoddiButton } from '../Buttons';

const Error404Page = () => {
  const navigate = useNavigate();

  const goBack = () => navigate(-1);

  return (
    <Stack className='-m-40 min-h-screen' justifyContent='center' alignItems='center' textAlign='center'>
      <div className='flex flex-col items-center gap-6'>
        <p className='font-bold text-8 text-primary-darkPurple'>
          <Trans>Page Not Found</Trans>
        </p>

        <p className='w-2/3 text-5 text-primary-darkPurple'>
          <Trans>
            The page you are looking for has either been moved or no longer exists. If we have linked incorrectly, we
            would be very happy if you send us a message.
          </Trans>
        </p>
        <NoddiButton onClick={goBack}>
          &larr;
          <Trans>Go Back</Trans>
        </NoddiButton>
      </div>
    </Stack>
  );
};

export { Error404Page };
