import { Trans, plural, t } from '@lingui/macro';
import { URLKeys, noddiAsync } from 'noddi-async';
import { useAuthContext } from 'noddi-provider';
import { CouponCard, ErrorPage, LoadingScreen, NoddiButton, NoddiDialog, NoddiIcon, colors } from 'noddi-ui';
import { useState } from 'react';

import HomeCardWrapper from '../../components/Home/HomeCardWrapper';
import HomeContentWrapper from '../../components/Layouts/HomeContentWrapper';
import NewAppointmentLink from '../../components/Links/NewAppointment';
import CopyReferralLink from '../Referrals/CopyReferralLink';
import ReferredUsers from '../Referrals/ReferredUsers';

const Bonus = () => {
  const { currentUserGroupId } = useAuthContext();

  const [showReferredUsers, setShowReferredUsers] = useState(false);

  const {
    data: coupons,
    isPending: isCouponsPending,
    error: couponsError
  } = noddiAsync.useGet({
    type: URLKeys.getUserGroupCoupons,
    input: { id: currentUserGroupId as number, includeUsedOnBookingIds: [] }
  });

  const {
    isPending: isReferralPending,
    data: referral,
    error: referralError
  } = noddiAsync.useGet({
    type: URLKeys.getReferrals,
    input: { userGroupId: currentUserGroupId as number },
    queryConfig: {
      enabled: !!currentUserGroupId
    }
  });

  if (isCouponsPending || isReferralPending) {
    return <LoadingScreen />;
  }
  if (couponsError) {
    return <ErrorPage apiError={couponsError} />;
  }
  if (referralError) {
    return <ErrorPage apiError={referralError} />;
  }

  const friendsText = plural(referral.referrals.length, {
    one: 'friend',
    other: 'friends'
  });
  return (
    <HomeContentWrapper className='relative' title={t`Bonus`}>
      <div className='absolute right-6 sm:top-4'>
        <NewAppointmentLink defaultSize />
      </div>
      <div className='flex flex-col gap-3 text-primary-darkPurple'>
        <div className='flex flex-col gap-3 md:flex-row'>
          <CopyReferralLink referralCode={referral.referralCode} />
          <HomeCardWrapper className='flex w-full flex-col justify-center '>
            <p className='font-bold text-5'>
              <Trans>How it works</Trans>
            </p>
            <div className='flex items-center justify-between'>
              <ul className='w-full list-disc pl-5'>
                <li>
                  <Trans>Invite one or more friends</Trans>
                </li>
                <li>
                  <Trans>Get up to 300 kroner discount</Trans>
                </li>
                <a
                  className='-ml-6 flex w-fit items-center gap-2 whitespace-normal text-nowrap text-primary-purple  underline-offset-4 hover:underline active:text-primary-purple/60 xs:hidden'
                  href='https://www.noddi.co/no/verving'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <NoddiIcon name='ArrowRight' size='small' color={colors.primary.purple} />
                  <Trans>Read more</Trans>
                </a>
              </ul>
              <a
                className='hidden w-fit items-center gap-2 whitespace-normal text-nowrap px-4 py-2.5 text-primary-purple underline-offset-4  hover:underline active:text-primary-purple/60  xs:flex'
                href='https://www.noddi.co/no/verving'
                target='_blank'
                rel='noopener noreferrer'
              >
                <Trans>Read more</Trans>
                <NoddiIcon name='ArrowRight' size='small' color={colors.primary.purple} />
              </a>
            </div>
          </HomeCardWrapper>
        </div>
        <HomeCardWrapper className='flex w-full flex-col gap-4'>
          <div className='flex items-center justify-between'>
            <div className='flex flex-col gap-2 md:gap-4'>
              <p className='text-4'>
                <Trans>I have referred</Trans>:
              </p>
              <div className='flex items-center gap-4'>
                <NoddiIcon name='Users' size='large' color={colors.primary.darkPurple} />
                <p className='font-bold text-5'>
                  {referral.referrals.length} {friendsText}
                </p>
              </div>
            </div>
            {!!referral.referrals.length && (
              <NoddiButton variant='link' endIcon='ArrowRight' onClick={() => setShowReferredUsers(true)} size='small'>
                <Trans>Details</Trans>
              </NoddiButton>
            )}
          </div>
        </HomeCardWrapper>

        <h2 className='font-bold text-6 md:text-8'>
          <Trans>My coupons</Trans>
        </h2>
        <div className='grid grid-cols-1 gap-4 md:grid-cols-2'>
          {coupons?.map((coupon) => (
            <CouponCard
              key={coupon.id}
              namePublic={coupon.namePublic}
              descriptionPublic={coupon.descriptionPublic}
              amount={coupon.amount}
              amountPercentage={coupon.amountPercentage}
            />
          ))}
        </div>

        <NoddiDialog
          title={t`Your referred friends`}
          onClose={() => setShowReferredUsers(false)}
          open={showReferredUsers}
        >
          {!!referral.referrals.length && <ReferredUsers referrals={referral.referrals} />}
        </NoddiDialog>
      </div>
    </HomeContentWrapper>
  );
};

export default Bonus;
