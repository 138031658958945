import { Trans, t } from '@lingui/macro';
import { Box, Stack, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import { URLKeys, noddiAsync } from 'noddi-async';
import { invalidateQueryKey } from 'noddi-async/src/utils';
import { useAuthContext } from 'noddi-provider';
import {
  ApiErrorMessage,
  LoadingScreen,
  NoddiButton,
  NoddiCollapseCard,
  NoddiFormTextInput,
  SMSLogin,
  SectionSpacer,
  UserRegistrationForm,
  colors,
  useIsMobile
} from 'noddi-ui';
import { formatCurrencyAmount } from 'noddi-util';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import routes from '../../appRoutes';
import { ContentContainer, InnerContainer, TopContainer } from '../../components/Layouts/Container';
import tracking from '../../tracking';
import { getImageSrc } from '../../utils/resolveCompanyLogo';
import { membershipDetailSchema } from './validators';

type FormInput = {
  membershipNumber: string;
  dateOfBirth: string;
};

const FormErrorMessage = styled.p`
  color: #f44336;
  margin: 0;
  padding: 0;
`;

const getDateOfBirthDto = (dateOfBirth: string) => {
  if (dateOfBirth === '' || dateOfBirth.length < 8) {
    return null;
  }
  const dateOfBirthStripped = dateOfBirth.replace(/[./-]/g, '');

  const year = dateOfBirthStripped.slice(-4);
  const month = dateOfBirthStripped.slice(2, 4);
  const day = dateOfBirthStripped.slice(0, 2);
  return `${year}-${month}-${day}`;
};

const buildBodyParams = (data: FormInput, userGroupId: number, phoneNumber: string) => {
  const memberNumber = data.membershipNumber;
  const dateOfBirth = getDateOfBirthDto(data.dateOfBirth);

  if (memberNumber) {
    return {
      userGroupId,
      memberNumber,
      phoneNumber: null,
      dateOfBirth: null
    };
  }

  if (dateOfBirth) {
    return {
      userGroupId,
      memberNumber: null,
      phoneNumber,
      dateOfBirth
    };
  }

  return null;
};

const ServiceHeader = ({
  isMobile,
  service,
  price,
  showPriceBottom,
  extraPriceInfo,
  icon
}: {
  isMobile: boolean;
  service: string;
  price: number;
  extraPriceInfo?: string;
  showPriceBottom?: boolean;
  icon: React.ReactNode;
}) => {
  const newPrice = Math.round(price * 0.9 * 1) / 1;

  return (
    <Stack>
      <Stack direction='row' alignItems='center'>
        <Stack direction='row' alignItems='center'>
          {icon}
          <Typography ml={2} variant='h6'>
            {service}&nbsp;
          </Typography>
          {showPriceBottom ? null : (
            // TODO: integrate price international number formatting module
            <Typography color='text.secondary' fontSize={isMobile ? 12 : undefined}>
              - fra <b>{formatCurrencyAmount(newPrice, 0)}</b> (<s> {formatCurrencyAmount(price, 0)}</s>){' '}
              {extraPriceInfo}
            </Typography>
          )}
        </Stack>{' '}
      </Stack>
      {showPriceBottom ? (
        // TODO: integrate price international number formatting module
        <Typography color='text.secondary' fontSize={isMobile ? 12 : undefined}>
          - fra <b>{formatCurrencyAmount(newPrice, 0)}</b> (<s>{formatCurrencyAmount(price, 0)}</s>) {extraPriceInfo}
        </Typography>
      ) : null}
    </Stack>
  );
};

const MembershipNumberForm = () => {
  const { userData, currentUserGroupId } = useAuthContext();

  const navigate = useNavigate();
  const [customErrorMessage, setCustomErrorMessage] = useState<string | null>(null);
  const [membershipLogin, setMembershipLogin] = useState<boolean>(true);

  const onSubmit = async (data: FormInput) => {
    setCustomErrorMessage(null);

    const phoneNumber = userData?.user.phoneNumber;

    if (!currentUserGroupId) {
      return;
    }

    if (!phoneNumber) {
      setCustomErrorMessage(
        t`Your profile does not have a registered phone number. Contact customer service and we will fix it for you`
      );
      return;
    }

    try {
      const bodyParams = buildBodyParams(data, currentUserGroupId, phoneNumber);
      // we should always have bodyParams, as we are disabling the submit button if the form is not valid
      if (bodyParams) {
        await mutateAsync(bodyParams);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const initialValues = {
    membershipNumber: '',
    dateOfBirth: ''
  };

  const {
    mutateAsync,
    error: apiError,
    isPending
  } = noddiAsync.usePost({
    type: URLKeys.postNafMembership,
    queryConfig: {
      onSuccess: async ({ data }) => {
        const { membershipAdded } = data;

        if (membershipAdded) {
          invalidateQueryKey({ urlKey: URLKeys.getUser });

          navigate(routes.confirmation.getPath({ params: 'type=membership_added&membershipName=NAF' }));
        } else {
          setCustomErrorMessage(t`Something went wrong, please try again later`);
        }
      }
    }
  });

  const dateOfBirthTitle = t`Date of birth (dd.mm.yyyy)`;

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={membershipDetailSchema(membershipLogin)}
    >
      {({ isValid, isSubmitting }) => (
        <Form>
          <div style={{ display: membershipLogin ? 'block' : 'none' }}>
            <SectionSpacer fullWidth>
              <NoddiFormTextInput name='membershipNumber' label={t`NAF membership number`} placeholder='1234...' />
            </SectionSpacer>
            <div style={{ marginTop: '1em', cursor: 'pointer' }} onClick={() => setMembershipLogin(!membershipLogin)}>
              <p style={{ fontSize: 18, textDecoration: 'underline' }}>
                <Trans>Don't remember your membership number? Register date of birth instead</Trans>
              </p>
            </div>
          </div>

          <div style={{ display: membershipLogin ? 'none' : 'block' }}>
            <SectionSpacer fullWidth>
              <NoddiFormTextInput name='dateOfBirth' label={dateOfBirthTitle} placeholder='DD.MM.ÅÅÅÅ' />
            </SectionSpacer>
            <div style={{ marginTop: '1em', cursor: 'pointer' }} onClick={() => setMembershipLogin(!membershipLogin)}>
              <p style={{ fontSize: 18, textDecoration: 'underline' }}>
                <Trans>Register with your membership number</Trans>
              </p>
            </div>
          </div>
          {apiError && <ApiErrorMessage error={apiError} />}
          {customErrorMessage && <FormErrorMessage>{customErrorMessage}</FormErrorMessage>}

          <NoddiButton
            style={{ marginTop: '24px' }}
            type='submit'
            fullWidth
            disabled={!isValid || isSubmitting || isPending}
            loading={isSubmitting || isPending}
          >
            <Trans>Confirm membership</Trans>
          </NoddiButton>
        </Form>
      )}
    </Formik>
  );
};

const UserAlreadyAMember = () => {
  return (
    <>
      <Typography marginTop={3} variant='body1'>
        <Trans>
          You are registered as a member with NAF in our systems, and the membership benefits are linked to your
          account!
        </Trans>
      </Typography>
    </>
  );
};

const MembershipDetails = () => {
  const isMobile = useIsMobile();
  const imageSize = isMobile ? 48 : 64;
  const { isLoggedIn, getCurrentUserGroupId } = useAuthContext();
  const userGroupId = getCurrentUserGroupId();

  const { data: isMemberData, isLoading } = noddiAsync.useGet({
    type: URLKeys.getIsNAFMember,
    input: { userGroupId: userGroupId as number },
    queryConfig: {
      enabled: !!userGroupId
    }
  });

  if (isLoading) {
    return <LoadingScreen />;
  }

  const isMember = isMemberData?.isMember || false;
  const safeSlug = 'naf';

  return (
    <TopContainer>
      <InnerContainer>
        <ContentContainer isMobile={isMobile}>
          <Typography variant='h1'>
            <span
              style={{
                display: 'flex',
                alignItems: 'center'
              }}
            >
              {getImageSrc(safeSlug) && (
                <Box
                  mr={2}
                  sx={{
                    height: imageSize,
                    width: imageSize,
                    '& img': {
                      width: '100%'
                    }
                  }}
                >
                  <img src={getImageSrc(safeSlug)} />
                </Box>
              )}
              <Trans>Membership benefits</Trans>
            </span>
          </Typography>
          <Typography mt={4} variant='h6' mb={2}>
            <Trans>
              As a member of NAF, you now get a 10% discount on all car services delivered to where the car is parked!
            </Trans>
          </Typography>
          <NoddiCollapseCard
            backgroundColor={colors.primary.white}
            header={
              <ServiceHeader
                service={t`Tire change`}
                isMobile={isMobile}
                price={699}
                icon={<img src='/assets/svgs/tire-change.svg' width={80} height={80} />}
              />
            }
            collapseBody={
              <Stack>
                <Typography>
                  <Trans>The tire change that comes to your home!</Trans>
                </Typography>
                <Typography color='text.secondary' mt={1}>
                  <Trans>
                    We come to where your car is parked and change to the season's tires. Do you need help carrying or
                    cleaning the rims, you can easily add this to the booking.
                  </Trans>
                </Typography>
              </Stack>
            }
          />
          <NoddiCollapseCard
            sx={{ marginTop: 1 }}
            backgroundColor={colors.primary.white}
            header={
              <ServiceHeader
                service={t`Tire hotel`}
                isMobile={isMobile}
                price={2399}
                extraPriceInfo={t` (including tire change)`}
                showPriceBottom={isMobile}
                icon={<img src='/assets/svgs/tire-storage.svg' width={80} height={80} />}
              />
            }
            collapseBody={
              <Stack>
                <Typography>
                  <Trans>Tire hotel that comes to your home!</Trans>
                </Typography>
                <Typography color='text.secondary' mt={1}>
                  <Trans>
                    We take your tires to where the car is parked, change to the season's tires and bring the tires back
                    at the hotel. The tire hotel includes washing of tires and rims, laser measurement of tires and safe
                    storage - all included in the price!
                  </Trans>
                </Typography>
              </Stack>
            }
          />
          {!isLoggedIn && (
            <>
              <Typography variant='h5' mt={3} style={{ color: colors.primary.orange }}>
                <Trans>Please log in to activate membership benefits</Trans>
              </Typography>
              <Typography variant='subtitle1' mt={2} style={{ color: colors.systemColors.grey }}>
                <Trans>We will automatically create a user for you, if you do not have one already.</Trans>
              </Typography>
              <SMSLogin
                className='mt-4'
                userRegistrationForm={(phoneNumber) => (
                  <UserRegistrationForm
                    phoneNumber={phoneNumber}
                    skipPasswordCreation
                    trackUserIdentity={(user) => {
                      tracking.trackUserIdentity(user);
                    }}
                  />
                )}
              />
            </>
          )}
          {isLoggedIn && !isMember && <MembershipNumberForm />}
          {isLoggedIn && isMember && <UserAlreadyAMember />}
        </ContentContainer>
      </InnerContainer>
    </TopContainer>
  );
};
export default MembershipDetails;
