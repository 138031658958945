import { t } from '@lingui/macro';
import { Form, Formik } from 'formik';
import { URLKeys, noddiAsync } from 'noddi-async';
import { invalidateQueryExactMatch } from 'noddi-async/src/utils';
import { useAuthContext } from 'noddi-provider';
import { useNoddiToast } from 'noddi-provider/src/utils/toast';
import {
  ApiErrorMessage,
  LoadingScreen,
  NoddiAsyncError,
  NoddiButton,
  NoddiCard,
  NoddiContainer,
  NoddiFormTextInput,
  SimpleGridLayout
} from 'noddi-ui';
import * as Yup from 'yup';

const paths = {
  home: '/home',
  profile: '/profile'
};

const getPageTitles = () => {
  return {
    home: t`Home`,
    profile: t`Profile`
  };
};

const breadCrumbs = () => {
  return {
    links: [
      {
        title: getPageTitles().home,
        path: paths.home
      },
      {
        title: getPageTitles().profile,
        path: paths.profile
      }
    ]
  };
};

const getValidationSchema = () =>
  Yup.object({
    firstName: Yup.string().required(t`Required`),
    lastName: Yup.string().required(t`Required`)
  });

export const Profile = ({ hideHeader }: { hideHeader?: boolean }) => {
  const { userData } = useAuthContext();
  const { noddiToast } = useNoddiToast();

  const {
    data: user,
    error: userError,
    isPending: isUserPending
  } = noddiAsync.useGet({
    type: URLKeys.getUser,
    input: { id: userData?.user.id },
    queryConfig: {
      enabled: !!userData?.user.id
    }
  });

  const { mutateAsync: updateUser } = noddiAsync.usePatch({
    type: URLKeys.patchUser,
    queryConfig: {
      onSuccess: async () => {
        invalidateQueryExactMatch({ urlKey: URLKeys.getUser });
        noddiToast.success(t`Profile updated successfully`);
      },
      onError: async (error: NoddiAsyncError) => {
        noddiToast.error(error.message);
      }
    }
  });

  if (!userData || isUserPending) {
    return <LoadingScreen />;
  }

  if (userError) {
    return <ApiErrorMessage error={userError} />;
  }

  const props = hideHeader
    ? { description: t`Your profile` }
    : { breadcrumbProps: breadCrumbs(), header: getPageTitles().profile, description: t`Your profile` };

  return (
    <NoddiContainer {...props}>
      <Formik
        initialValues={{
          email: user.email,
          firstName: user.firstName,
          lastName: user.lastName,
          phoneNumber: user.phoneNumber
        }}
        enableReinitialize={true}
        validationSchema={getValidationSchema()}
        onSubmit={async (values) => {
          const valuesToSubmit = {
            id: user.id,
            email: values.email,
            firstName: values.firstName,
            lastName: values.lastName,
            phoneNumber: values.phoneNumber
          };

          await updateUser({
            ...valuesToSubmit
          });
        }}
      >
        {({ isValid, isSubmitting }) => (
          <Form>
            <NoddiCard title={t`General`}>
              <SimpleGridLayout numColumns={2}>
                <NoddiFormTextInput name='firstName' label={t`First name`} />
                <NoddiFormTextInput name='lastName' label={t`Last name`} />
                <NoddiFormTextInput name='email' label={t`Email`} disabled={true} />
                <NoddiFormTextInput name='phoneNumber' label={t`Phone number`} disabled={true} />
              </SimpleGridLayout>
            </NoddiCard>

            <NoddiButton style={{ marginTop: '24px' }} disabled={!isValid} loading={isSubmitting} type='submit'>
              {t`Save`}
            </NoddiButton>
          </Form>
        )}
      </Formik>
    </NoddiContainer>
  );
};
