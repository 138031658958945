import { useAuthContext } from 'noddi-provider';

import HomeContent from './HomeContent';

const Home = () => {
  const { currentUserGroupId: userGroupId, hasTireHotel, userData } = useAuthContext();

  if (!userGroupId || !userData) {
    return null;
  }

  return <HomeContent userGroupId={userGroupId} hasTireHotel={!!hasTireHotel} userData={userData} />;
};

export default Home;
