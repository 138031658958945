import { Trans, plural } from '@lingui/macro';
import { URLKeys, noddiAsync } from 'noddi-async';
import { useAuthContext } from 'noddi-provider';
import { ErrorPage, LoadingScreen, NoddiButton } from 'noddi-ui';
import { useNavigate } from 'react-router-dom';

import routes from '../../appRoutes';
import HomeCardWrapper from '../../components/Home/HomeCardWrapper';

const Coupons = () => {
  const { currentUserGroupId } = useAuthContext();

  const navigate = useNavigate();

  const {
    data: coupons,
    isPending,
    error
  } = noddiAsync.useGet({
    type: URLKeys.getUserGroupCoupons,
    input: { id: currentUserGroupId as number, includeUsedOnBookingIds: [] }
  });

  if (isPending) {
    return <LoadingScreen />;
  }
  if (error) {
    return <ErrorPage apiError={error} />;
  }

  const userHasCoupons = !!coupons.length;

  const todaysRoutesText = plural(coupons.length, {
    one: 'coupon on your account!',
    other: 'coupons on your account!'
  });

  return (
    <div className='flex flex-col gap-2 md:gap-4'>
      <h2 className='font-bold text-6 md:text-8'>
        <Trans>Discounts</Trans>
      </h2>
      <HomeCardWrapper className='flex flex-col gap-5'>
        <p className='font-bold text-5'>
          {userHasCoupons ? <Trans>Hurray!</Trans> : <Trans>Invite friends to Noddi</Trans>}🥳
        </p>

        {userHasCoupons ? (
          <div className='flex gap-1'>
            <p>
              <Trans>You have</Trans>
            </p>
            <p className='font-bold'>{coupons.length}</p>
            <p>{todaysRoutesText}</p>
          </div>
        ) : (
          <p>
            <Trans>
              Refer a friend and get you both get discounts on future bookings. Save money and time together with Noddi!
            </Trans>
            💜
          </p>
        )}
        <div className='flex justify-end'>
          <NoddiButton variant='secondary' onClick={() => navigate(routes.myCoupons.getPath())} size='small'>
            <Trans>See details</Trans>
          </NoddiButton>
        </div>
      </HomeCardWrapper>
    </div>
  );
};
export default Coupons;
