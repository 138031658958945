import { i18n } from '@lingui/core';

import { NoddiFeedbackBox } from '../../../../molecules';
import { useCaptureException } from '../utils';
import { NoddiAsyncError } from './NoddiAsyncError';
import { getErrorCodes, getGenericErrorMessages } from './errorCodes';

interface ApiErrorMessageProps {
  error: NoddiAsyncError | Array<NoddiAsyncError | null>;
  isSmallVersion?: boolean;
}

export const mapErrorCodeToUiMessage = ({ error }: { error: NoddiAsyncError }) => {
  const genericErrorMessageIdentifiers = getGenericErrorMessages();
  const errorCodes = getErrorCodes();

  const genericErrorMessage = genericErrorMessageIdentifiers.genericErrorMessage;
  const errors = error.response?.data.errors ?? [];

  if (!errors) {
    return genericErrorMessage;
  }
  if (errors.length === 1) {
    const code = errors[0]?.code;

    return errorCodes[code as keyof typeof errorCodes] ?? genericErrorMessage;
  } else if (errors.length > 1) {
    //writes reducer that iterates through errors and returns a string
    const message = errors.reduce((prev, curr, index) => {
      const code = curr?.code ?? '';

      const errorCode = errorCodes[code as keyof typeof errorCodes] ?? genericErrorMessage;
      if (index === errors.length - 1) {
        prev += errorCode;
      } else {
        if (errorCode) {
          prev += `${errorCode} ${i18n._('and')} `;
        }
      }

      return prev;
    }, '');
    return message;
  }
  return genericErrorMessage;
};

export function ApiErrorMessage({ error, isSmallVersion }: ApiErrorMessageProps) {
  const errorToRender = Array.isArray(error) ? error.find((e) => e !== null) : error;
  useCaptureException(errorToRender, 'ApiErrorMessage');

  if (!errorToRender) {
    return null;
  }

  return (
    <div className='mt-2 '>
      <NoddiFeedbackBox
        variant='error'
        description={mapErrorCodeToUiMessage({ error: errorToRender })}
        size={isSmallVersion ? 'small' : 'default'}
      />
    </div>
  );
}

interface ErrorMessageProps {
  message: string;
}

export function ErrorMessage({ message }: ErrorMessageProps) {
  useCaptureException(message, 'ErrorMessage');

  return (
    <div className='mt-2'>
      <NoddiFeedbackBox variant='error' description={message} />
    </div>
  );
}
