/* eslint-disable no-prototype-builtins */
import { AvailableBookingTimeWindowsByDateNew } from 'noddi-async/src/types';
import { DateFormats, format } from 'noddi-util';

export const parseTimeSlotFromPublicTimes = (startPublic: string, endPublic: string): string => {
  const startLocal = new Date(startPublic);
  const endLocal = new Date(endPublic);

  const startHour = format(startLocal, DateFormats.HOURS);
  const endHour = format(endLocal, DateFormats.HOURS);

  const formattedSlot = `${startHour.slice(0, 2)}-${endHour.slice(0, 2)}`;
  return formattedSlot;
};

export const sortTimeSlots = (timeSlots: string[]): string[] => {
  const isValidFormat = (timeSlot: string): boolean => {
    // Regex to check the HH-MM format
    return /^\d{2}-\d{2}$/.test(timeSlot);
  };

  // Filter out invalid time slots
  const validTimeSlots = timeSlots.filter(isValidFormat);

  return validTimeSlots.sort((a, b) => {
    const [startHourA, endHourA] = a.split('-').map(Number);
    const [startHourB, endHourB] = b.split('-').map(Number);

    // First, compare based on the start time
    if (startHourA !== startHourB) {
      return startHourA! - startHourB!;
    }

    // If start times are the same, compare based on the end time
    return endHourA! - endHourB!;
  });
};

export const getUniqueTimeSlots = (data: AvailableBookingTimeWindowsByDateNew): string[] => {
  const timeSlotsSet = new Set<string>();

  for (const date in data) {
    if (data.hasOwnProperty(date)) {
      const slots = data[date];
      for (const slotKey in slots) {
        if (slots.hasOwnProperty(slotKey) && slots[slotKey] !== null) {
          const slotData = slots[slotKey];

          // Check if slotData is not null or undefined
          if (slotData && slotData?.startPublic && slotData?.endPublic) {
            if (checkIfSlotHasData(data, slotKey)) {
              const formattedSlot = parseTimeSlotFromPublicTimes(slotData.startPublic, slotData.endPublic);
              timeSlotsSet.add(formattedSlot);
            }
          }
        }
      }
    }
  }

  return Array.from(timeSlotsSet);
};

export const checkIfSlotHasData = (input: AvailableBookingTimeWindowsByDateNew, slot: string): boolean => {
  for (const date in input) {
    if (input.hasOwnProperty(date)) {
      const dateTimeWindows = input[date];
      if (dateTimeWindows && dateTimeWindows.hasOwnProperty(slot) && dateTimeWindows[slot] !== null) {
        return true;
      }
    }
  }
  return false;
};
export const parseTimeWindowData = (input: AvailableBookingTimeWindowsByDateNew) => {
  const result = [];

  for (const date in input) {
    if (input.hasOwnProperty(date)) {
      const timeWindows = [];

      // Extract year, month, and day from the string
      const year = date.substring(0, 4);
      const month = date.substring(4, 6);
      const day = date.substring(6, 8);

      // Format it as "YYYY-MM-DD"
      const formattedDate = `${year}-${month}-${day}`;

      // Ensure that date exists in the input
      const dateTimeWindows = input[date];
      if (dateTimeWindows) {
        for (const timeWindow in dateTimeWindows) {
          timeWindows.push(dateTimeWindows[timeWindow] ?? null);
        }
      }

      result.push({
        date: formattedDate,
        timeWindows: timeWindows
      });
    }
  }

  return result;
};
