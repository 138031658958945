import { Trans } from '@lingui/macro';
import { NoddiButton } from 'noddi-ui';
import { useNavigate } from 'react-router-dom';

import routes from '../../appRoutes';

interface BookingConfirmationProps {
  createdResourceId?: string | null;
  patchedBookingId?: string | null;
}

const BookingConfirmation = ({ createdResourceId, patchedBookingId }: BookingConfirmationProps) => {
  const navigate = useNavigate();

  const bookingId = createdResourceId || patchedBookingId;

  return (
    <div className='mx-auto mt-22 flex max-w-2xl flex-col gap-4 p-4'>
      <h1 className='text-7 sm:text-13'>
        <Trans>Booking completed</Trans>
        🎉
      </h1>
      <div className='rounded-3xl bg-primary-white p-4'>
        <div className='flex flex-col gap-4 p-2'>
          <div className='flex flex-col gap-3'>
            <p>
              {patchedBookingId ? (
                <Trans>The booking was updated!</Trans>
              ) : (
                <Trans>The booking is successful, you will soon receive a confirmation via SMS.</Trans>
              )}
            </p>

            <p>
              <Trans>Thank you for choosing Noddi!</Trans>
            </p>
          </div>
          <div className='flex flex-col gap-3'>
            <p className='font-bold'>
              <Trans>Payment </Trans> 💰
            </p>
            <p>
              <Trans>
                You will receive a link via SMS with payment information after the job has been carried out by our
                employees.
              </Trans>
            </p>
          </div>
        </div>
      </div>
      {bookingId && (
        <div className='flex justify-end'>
          <NoddiButton onClick={() => navigate(routes.myBookingDetails.getPath({ id: bookingId }))}>
            <Trans>View booking</Trans>
          </NoddiButton>
        </div>
      )}
    </div>
  );
};

export default BookingConfirmation;
