/* eslint-disable  @typescript-eslint/no-explicit-any */
import Drawer from '@mui/material/Drawer';
import Stack from '@mui/material/Stack';

import { colors } from '../../../../../tailwind-design-preset';
import NavFooter from './SideNavFooter';
import { SideNavHeader } from './SideNavHeader';
import { SideNavItems } from './SideNavItems';
import { VerticalLayoutProps } from './VerticalLayoutProps';

export const SIDE_NAV_WIDTH = 278;

export const PaperProps = () => {
  return {
    sx: {
      '--nav-item-color': colors.primary.white,
      '--nav-item-hover-bg': colors.primary.purple,
      '--nav-item-active-bg': colors.primary.purple,
      '--nav-item-active-color': colors.secondary.white,
      '--nav-item-icon-color': colors.primary.white,
      '--nav-item-icon-active-color': colors.primary.orange,
      backgroundColor: colors.primary.darkPurple,
      width: SIDE_NAV_WIDTH
    }
  };
};

export const DrawerContent = ({
  navItems,
  CustomNavElement,
  LanguagePicker,
  customPathToProfile,
  hideProfile
}: VerticalLayoutProps) => {
  return (
    <Stack sx={{ height: '100%', direction: 'column', justifyContent: 'space-between' }}>
      <div>
        <SideNavHeader />
        <SideNavItems navItems={navItems} />
      </div>
      <NavFooter
        CustomNavElement={CustomNavElement}
        LanguagePicker={LanguagePicker}
        customPathToProfile={customPathToProfile}
        hideProfile={hideProfile}
      />
    </Stack>
  );
};

export const PermanentSideNav = ({
  navItems,
  CustomNavElement,
  LanguagePicker,
  customPathToProfile
}: VerticalLayoutProps) => {
  return (
    <Drawer anchor='left' open PaperProps={PaperProps()} variant='permanent'>
      <DrawerContent
        navItems={navItems}
        CustomNavElement={CustomNavElement}
        LanguagePicker={LanguagePicker}
        customPathToProfile={customPathToProfile}
      />
    </Drawer>
  );
};
