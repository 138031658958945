import { Trans, t } from '@lingui/macro';
import { UserDataProps } from 'noddi-async/src/types';

import NewAppointmentLink from '../../components/Links/NewAppointment';

type HomePageHeaderProps = {
  userData: UserDataProps;
  isFirstTimeCustomer: boolean;
  isMobile: boolean;
};

const HomePageHeader = ({ userData, isFirstTimeCustomer, isMobile }: HomePageHeaderProps) => (
  <div className='flex flex-col gap-3'>
    <div className='flex items-center justify-between gap-6'>
      <h1 className='font-bold text-9 md:text-12'>{t`Hi,` + ' ' + userData.user.firstName}</h1>
      {!isFirstTimeCustomer && !isMobile && <NewAppointmentLink defaultSize />}
    </div>
    <p className='font-bold text-5 text-primary-purple md:text-6'>
      {!isFirstTimeCustomer && <Trans>Welcome back!</Trans>}
    </p>
    {!isFirstTimeCustomer && isMobile && <NewAppointmentLink />}
  </div>
);

export default HomePageHeader;
