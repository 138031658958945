import { Trans } from '@lingui/macro';
import { Typography } from '@mui/material';
import { Stack } from '@mui/system';

import { NoddiIcon } from '../../../../atoms';
import { NoddiCollapseCard } from '../../../Elements/Cards/NoddiCollapseCard';

const GeneralTireInfo = () => {
  return (
    <NoddiCollapseCard
      sx={{ marginTop: 2 }}
      header={
        <p className='flex items-center gap-3 text-4.5 font-semibold'>
          <div>
            <NoddiIcon name='Info' />
          </div>
          <Trans>Standards for winter and summer tires</Trans>
        </p>
      }
      collapseBody={
        <Stack marginTop={2}>
          <Typography variant='h6'>
            <Trans>Winter tires</Trans>
          </Typography>
          <Typography color='text.secondary' mt={1}>
            <Trans>
              In Norway, the minimum requirement for tread depth on winter tires is 3 mm. It is illegal to drive with
              winter tires that are under 3 mm. We recommend changing tires when the tread depth is below 4 mm. When the
              tread depth is below 4 mm, the braking distance increases considerably, and the tire loses its
              performance.
            </Trans>
          </Typography>

          <Typography variant='h6' marginTop={3}>
            <Trans>Summer tires</Trans>
          </Typography>
          <Typography color='text.secondary' mt={1}>
            <Trans>
              In Norway, the minimum requirement for tread depth on summer tires is 1.6 mm. It is illegal to drive with
              summer tires under 1.6 mm. We recommend changing tires when the tread depth is below 3 mm. When the tread
              depth is below 3 mm, the braking distance increases considerably, and the tire loses its performance.{' '}
            </Trans>
          </Typography>
        </Stack>
      }
    />
  );
};

export { GeneralTireInfo };
