import { t } from '@lingui/macro';
import { ServiceCategoryFromAddress } from 'noddi-async/src/types';
import { formatCurrencyAmount } from 'noddi-util';

import { colors } from '../../../../tailwind-design-preset';
import { NoddiIcon } from '../../../atoms';
import { getServiceCategoryIcon, getServiceCategoryTranslations } from './utils';

type Props = {
  serviceCategory: ServiceCategoryFromAddress;
  navigateToNextStepWhenAvailableService?: () => void;
};

export const AvailableServiceCategory = ({
  serviceCategory,
  navigateToNextStepWhenAvailableService
}: Props): React.ReactElement => {
  const data = getServiceCategoryTranslations({ serviceCategoryName: serviceCategory.name });
  const iconName = getServiceCategoryIcon({ serviceCategoryName: serviceCategory.name });

  return (
    <div className='flex w-fit gap-2' onClick={navigateToNextStepWhenAvailableService}>
      <NoddiIcon className='mt-[2px]' name={iconName} color={colors.primary.black} size='large' />

      <div className='flex flex-col'>
        <span className='text-5 sm:text-6'>{data.name}</span>
        <div className='flex gap-1'>
          <span className='text-3.5 sm:text-4'>{t`from`}</span>
          <span className='text-3.5 font-semibold sm:text-4'>
            {formatCurrencyAmount(serviceCategory.price, 0, serviceCategory.currency)}
          </span>
        </div>
      </div>
    </div>
  );
};
