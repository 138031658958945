import { Trans, t } from '@lingui/macro';
import { URLKeys, noddiAsync } from 'noddi-async';
import { invalidateQueryExactMatch } from 'noddi-async/src/utils';
import { useAuthContext } from 'noddi-provider';
import { NoddiButton, NoddiIcon, NoddiTextInput } from 'noddi-ui';
import { useState } from 'react';

interface EditCommentProps {
  commentsUser: string;
  bookingId: string;
}

const EditComment = ({ commentsUser, bookingId }: EditCommentProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const [userComment, setUserComment] = useState(commentsUser);
  const { currentUserGroupId: userGroupId } = useAuthContext();

  const {
    mutateAsync: updateBooking,
    isPending: isUpdateBookingLoading,
    isError
  } = noddiAsync.usePatch({
    type: URLKeys.patchBooking,
    queryConfig: {
      onSuccess: async () => {
        await invalidateQueryExactMatch({
          urlKey: URLKeys.getUserBooking,
          input: { userGroupId: userGroupId as number, bookingId }
        });
        setIsEditing(false);
      }
    }
  });

  const hasAnyComment = commentsUser && commentsUser.length > 0;
  return (
    <>
      <div className='mt-4 flex flex-col'>
        {commentsUser && (
          <div className='flex items-center gap-2'>
            <div>
              <NoddiIcon name='ChatRounded' size='medium' />
            </div>
            <p>{commentsUser}</p>
          </div>
        )}

        {!isEditing && (
          <NoddiButton startIcon='Edit' variant='link' onClick={() => setIsEditing((prev) => !prev)} className='-ml-4'>
            <p>{hasAnyComment ? t`Edit comment` : t`Add a comment`}</p>
          </NoddiButton>
        )}
      </div>
      {isEditing && (
        <div className='mt-2'>
          <NoddiTextInput
            value={userComment}
            onChange={(newValue) => setUserComment(newValue.target.value)}
            error={isError}
          />
          {isError && (
            <p className='text-signal-danger'>
              <Trans>We had trouble updating your booking. Please try again</Trans>
            </p>
          )}
          <div className='mt-2 flex justify-end'>
            <NoddiButton
              size='small'
              variant='secondary'
              loading={isUpdateBookingLoading}
              onClick={async () =>
                await updateBooking({
                  id: parseInt(bookingId),
                  commentsUser: userComment
                })
              }
            >
              <Trans>Save</Trans>
            </NoddiButton>
          </div>
        </div>
      )}
    </>
  );
};

export default EditComment;
