import { Trans, t } from '@lingui/macro';
import { ServerTypes } from 'noddi-async';
import { NoddiButton, NoddiDialog } from 'noddi-ui';
import { cn } from 'noddi-ui/src/helpers/utilts';
import { formatCurrencyAmount } from 'noddi-util';
import { useState } from 'react';

import { useBookingContext } from '../../../../contexts/BookingContext';
import useSelectSalesItem from '../../../../hooks/useSelectSalesItem';
import tracking from '../../../../tracking';
import { SelectedCar } from '../../interfaces';
import { IncompatibleDialog } from './IncompatibleDialog';

type ServiceItemProps = {
  name: string;
  price: number;
  description?: string;
  shortDescription?: string;
  car: SelectedCar;
  salesItem: ServerTypes.AvailableSalesItem;
  onSalesItemClick: () => void;
};

const SalesItem = ({
  name,
  description,
  shortDescription,
  price,
  car,
  salesItem,
  onSalesItemClick
}: ServiceItemProps) => {
  const [openIncompatibleDialog, setOpenIncompatibleDialog] = useState(false);
  const [showFullDescription, setShowFullDescription] = useState(false);
  const { isSelected } = useSelectSalesItem();
  const isAlreadySelected = isSelected({ licensePlateNumber: car.licensePlateNumber, salesItem });
  const { incompatibleServiceCategories } = useBookingContext();

  const isCurrentlyIncompatible = incompatibleServiceCategories.find(
    (category) => category.serviceCategoryId === salesItem.serviceCategory.id
  );

  const isFree = price === 0;
  const priceFormatted = isFree ? t`Free` : formatCurrencyAmount(price);

  return (
    <>
      <IncompatibleDialog
        salesItem={salesItem}
        open={openIncompatibleDialog}
        onClose={() => {
          setOpenIncompatibleDialog(false);
        }}
      />
      <div
        className={cn(
          'relative  py-3 sm:py-5 px-4 sm:px-6 pt-0',
          isAlreadySelected
            ? 'bg-primary-purple rounded-lg'
            : '[&:not(:last-child)]:border-b-[1.5px] [&:not(:last-child)]:border-primary-purple'
        )}
      >
        <div className={cn('flex flex-col gap-2', isAlreadySelected ? 'text-primary-white' : 'text-primary-black')}>
          <div className='flex justify-between gap-2'>
            <p className='text-5'>{name}</p>
          </div>
          <p className={cn('text-4 font-semibold', isFree && !isAlreadySelected ? 'text-signal-success' : '')}>
            {priceFormatted}
          </p>

          {shortDescription && <p>{shortDescription} </p>}
          <div className='flex justify-end'>
            {!isCurrentlyIncompatible && (
              <div className='flex  items-center gap-2'>
                {description && (
                  <NoddiButton
                    startIcon='ArrowSquareRightCorner'
                    variant={isAlreadySelected ? 'lightLink' : 'link'}
                    onClick={() => {
                      tracking.track('salesItemDescriptionDetailsClicked', { salesItemName: name });
                      setShowFullDescription(true);
                    }}
                  >
                    <Trans>Details</Trans>
                  </NoddiButton>
                )}
                <NoddiButton
                  variant='secondary'
                  startIcon={isAlreadySelected ? 'Minus' : 'Plus'}
                  size='small'
                  onClick={onSalesItemClick}
                >
                  {isAlreadySelected ? <Trans>Remove</Trans> : <Trans>Add</Trans>}
                </NoddiButton>
              </div>
            )}
          </div>
          {isCurrentlyIncompatible && (
            <NoddiButton
              className='pl-0 text-left'
              variant='link'
              onClick={() => {
                setOpenIncompatibleDialog(true);
              }}
            >
              <Trans>Why am I not able to select this?</Trans>
            </NoddiButton>
          )}
        </div>
      </div>
      <NoddiDialog title={name} onClose={() => setShowFullDescription(false)} open={showFullDescription}>
        {description}
      </NoddiDialog>
    </>
  );
};

export { SalesItem };
