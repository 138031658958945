import { Trans, t } from '@lingui/macro';
import { Stack, useMediaQuery } from '@mui/material';
import { URLKeys, noddiAsync } from 'noddi-async';
import { useAuthContext } from 'noddi-provider';
import { LoadingScreen, NoddiButton, NoddiContainer } from 'noddi-ui';
import { useEffectAfterMount } from 'noddi-util/src/hooks';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import appRoutes from '../../../../appRoutes';
import { useBookingContext } from '../../../../contexts/BookingContext';
import useSelectSalesItem from '../../../../hooks/useSelectSalesItem';
import { useTireHotelContext } from '../context';
import useStepContent from '../hooks/useStepContent';
import { TireStorageBookingSteps } from '../tireStorageBookingSteps';

interface TireHotelBookingFlowWrapperProps {
  isCancel?: boolean;
}

const TireHotelBookingFlowWrapper = ({ isCancel }: TireHotelBookingFlowWrapperProps) => {
  const { currentUserGroupId } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const { currentStep, setCurrentStep } = useTireHotelContext();
  const isMobile = useMediaQuery('(max-width:600px)');
  const { pathname } = useLocation();
  const { selectSalesItemForMultipleCars } = useSelectSalesItem();
  const isCancelingBooking = pathname.includes('/cancel');
  const {
    bookingInputData: { selectedCars }
  } = useBookingContext();
  const navigate = useNavigate();

  useEffectAfterMount(() => {
    navigate(appRoutes.homepage.getBasePath());
  }, [currentUserGroupId]);

  useEffect(() => {
    if (isCancel) {
      setCurrentStep(TireStorageBookingSteps.returnChoices);
    }
  }, [isCancel]);

  const { data, isPending } = noddiAsync.useGet({
    type: URLKeys.getSalesItemsForBooking,
    input: {
      group: 'tire-storage-renew'
    },
    queryConfig: {
      staleTime: Infinity,
      enabled: !isCancelingBooking
    }
  });

  const { data: membershipData, isPending: isMembershipsPending } = noddiAsync.useGet({
    type: URLKeys.getMembershipPrograms,
    input: { userGroupId: currentUserGroupId as number },
    queryConfig: {
      enabled: !!currentUserGroupId,
      staleTime: Infinity
    }
  });

  const stepContent = useStepContent({
    setIsLoading,
    membershipData
  });

  useEffect(() => {
    if (!isPending && data && !isCancelingBooking) {
      // we know that this endpoint return only one sales item
      const renewTireStorageSalesItem = data?.[0];

      if (!renewTireStorageSalesItem) {
        return;
      }

      selectSalesItemForMultipleCars({
        salesItem: renewTireStorageSalesItem,
        licensePlateNumbers: selectedCars.map((car) => car.licensePlateNumber),
        isAddon: false,
        queryParams: {
          urlKey: URLKeys.getSalesItemsForBooking,
          queryInput: {
            group: 'tire-storage-renew'
          }
        }
      });
    }
  }, [isPending, data]);
  const currentStepContent = stepContent[currentStep];

  const onNextClicked = currentStepContent?.onNextClicked;
  const confirmText = currentStepContent?.confirmText;
  const back = currentStepContent?.back;
  const nextDisabled = currentStepContent?.nextDisabled;

  const onClose = () => {
    if (back) {
      back();
    } else {
      history.back();
    }
  };

  const hideBackButton = !isCancelingBooking && currentStep === TireStorageBookingSteps.address;
  const showBottomBar = currentStep !== TireStorageBookingSteps.timePicker;

  if (isMembershipsPending) {
    return <LoadingScreen />;
  }

  const isTimePickerStep = currentStep === TireStorageBookingSteps.timePicker;

  return (
    <div>
      <NoddiContainer>
        <Stack maxWidth={isTimePickerStep ? 'lg' : 'sm'} sx={{ marginLeft: 'auto', marginRight: 'auto' }}>
          <h1 className='mb-4 mt-6 text-7 sm:mt-4 sm:text-13'>{currentStepContent.title}</h1>
          {currentStepContent.renderComponent(isMobile)}

          {showBottomBar && (
            <StyledBottomBarStack numberOfButtons={!hideBackButton ? 2 : 1} isMobile={isMobile}>
              {!hideBackButton && (
                <NoddiButton onClick={onClose} variant='secondary' disabled={isLoading}>
                  {t`Back`}
                </NoddiButton>
              )}
              <NoddiButton
                onClick={async () => {
                  onNextClicked();
                }}
                variant='primary'
                disabled={isLoading || Boolean(nextDisabled?.())}
                loading={isLoading}
              >
                {confirmText ?? <Trans>Yes</Trans>}
              </NoddiButton>
            </StyledBottomBarStack>
          )}
        </Stack>
      </NoddiContainer>
    </div>
  );
};

export default TireHotelBookingFlowWrapper;

export const StyledBottomBarStack = styled.div<{ numberOfButtons: number; isMobile: boolean }>`
  position: static; /* Initially set to static */
  display: flex;
  gap: 12px;
  margin-top: 24px;
  width: 100%;
  justify-content: ${(props) => (props.isMobile ? 'center' : 'flex-end')};

  @media (max-width: 600px) {
    margin-top: 0px;
    margin-bottom: 0px;
    position: fixed; /* Change to fixed on small screens (up to 600px wide) */
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000; /* Adjust z-index as needed */
    background-color: #ffffff; /* Optional: Background color */
    padding: 24px 0px; /* Optional: Padding */
    box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1); /* Optional: Box shadow for visual separation */
    display: flex;
    justify-content: center;

    ${(props) =>
      props.numberOfButtons === 1
        ? `
      button {
        width: 90%;
        }
    `
        : `
      button {
        width: 40%;
      }
      `}
  }
`;
