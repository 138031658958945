import { useNavigate } from 'react-router-dom';

import routes from '../../../../appRoutes';
import { useBookingContext } from '../../../../contexts/BookingContext';
import useFetchAddons from '../../../../hooks/ApiHooks/useFetchAddons';
import { GoogleTrackingService } from '../../../../services/GoogleTrackingService';
import { useCouponStore } from '../../../../stores/CouponStore';
import { GtmEvents } from '../../../../types/gtmTracking';
import { useMembershipPrograms } from '../../hooks/useMembershipPrograms';

const useOnNextButtonClick = () => {
  const navigate = useNavigate();
  const { refetch: fetchAddons } = useFetchAddons();
  const { salesItems } = useBookingContext();
  const { data: membershipData } = useMembershipPrograms();
  const { coupons } = useCouponStore();

  const navigateToNextStep = async () => {
    const { data: addons } = await fetchAddons();

    const nextRoute =
      !addons || addons.length === 0 ? routes.newBookingTimeSlotStep.getPath() : routes.newBookingAddonsStep.getPath();

    if (!salesItems || salesItems.length === 0) {
      return navigate(nextRoute);
    }

    if (nextRoute === routes.newBookingTimeSlotStep.getPath()) {
      GoogleTrackingService.trackEvent({
        eventType: GtmEvents.beginCheckout,
        data: { salesItems, membershipData: membershipData ? membershipData : [], coupons }
      });
    }

    return navigate(nextRoute);
  };

  return { navigateToNextStep };
};

export default useOnNextButtonClick;
