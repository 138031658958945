import { Trans, t } from '@lingui/macro';
import { URLKeys, noddiAsync } from 'noddi-async';
import { invalidateQueryExactMatch } from 'noddi-async/src/utils';
import { useAuthContext } from 'noddi-provider';
import { NoddiButton, NoddiDialog, NoddiFeedbackBox } from 'noddi-ui';
import { useNavigate } from 'react-router-dom';

import routes from '../../../appRoutes';

type Props = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  bookingId: string;
  timeWindowId: number;
  formattedTimeWindow: string;
};

const EditBookingTimeWindowDialog = ({ isOpen, setIsOpen, bookingId, timeWindowId, formattedTimeWindow }: Props) => {
  const { currentUserGroupId: userGroupId } = useAuthContext();

  const navigate = useNavigate();

  const {
    mutateAsync: updateTimeWindow,
    isPending: isUpdateTimeWindowPending,
    isError
  } = noddiAsync.usePatch({
    type: URLKeys.patchBooking,
    queryConfig: {
      onSuccess: async () => {
        await invalidateQueryExactMatch({
          urlKey: URLKeys.getUserBooking,
          input: { userGroupId: userGroupId as number, bookingId }
        });
        navigate(routes.myBookingDetails.getPath({ id: bookingId }));
      }
    }
  });

  return (
    <NoddiDialog title={t`Change bookingtime?`} open={isOpen} onClose={() => setIsOpen(false)}>
      <div className='flex flex-col gap-2'>
        <p className='text-4.5'>
          <Trans>You have selected</Trans>
          <b> {formattedTimeWindow}</b>
        </p>
        <p className='mb-5'>
          <Trans>
            Changing the booking time is free. Rescheduling is available up to 24 hours before the start time.
          </Trans>
        </p>
        {isError && (
          <NoddiFeedbackBox
            variant='error'
            size='small'
            heading={t`We had trouble updating your booking. Please try again.`}
          />
        )}
        <div className='flex justify-end gap-2'>
          <NoddiButton
            onClick={async () =>
              await updateTimeWindow({
                id: parseInt(bookingId),
                bookingTimeWindowId: timeWindowId
              })
            }
            loading={isUpdateTimeWindowPending}
            variant='primary'
          >
            <Trans>Change time</Trans>
          </NoddiButton>
        </div>
      </div>
    </NoddiDialog>
  );
};

export default EditBookingTimeWindowDialog;
