import { Trans } from '@lingui/macro';
import { FixedBottomBarContainer, NoddiButton } from 'noddi-ui';
import { PropsWithChildren } from 'react';
import { useLocation } from 'react-router-dom';

import routes from '../../appRoutes';
import HelpScoutChat from '../../components/BookingFlow/HelpScoutChat';
import ShoppingCart from './Steps/Summary/ShoppingCart';

interface ScreenProps {
  onNextClicked?: () => void;
  disableNextButton?: boolean;
  hideNextButton?: boolean;
  hideShoppingCart?: boolean;
}

const FixedBottomBar = ({
  onNextClicked,
  disableNextButton,
  hideNextButton,
  hideShoppingCart
}: PropsWithChildren<ScreenProps>) => {
  const { pathname } = useLocation();

  const isTimePickerStep = pathname.includes(routes.newBookingTimeSlotStep.getBasePath());

  const isAdressStep = pathname.includes(routes.newBookingAddressStep.getBasePath());
  const isCarStep = pathname.includes(routes.newBookingCarStep.getBasePath());

  if (isTimePickerStep) {
    return null;
  }

  const showShoppingCart = !hideShoppingCart && !isAdressStep && !isCarStep;

  return (
    <FixedBottomBarContainer>
      <div className='mr-2'>
        <HelpScoutChat size={showShoppingCart ? 'small' : 'medium'} />
      </div>
      <div className='flex w-full items-center justify-between gap-3'>
        {showShoppingCart && (
          <div className='xs:hidden'>
            <ShoppingCart />
          </div>
        )}

        <div className='ml-auto flex gap-3'>
          <div className='hidden xs:block'>{!hideShoppingCart && !isAdressStep && <ShoppingCart />}</div>
          {!hideNextButton && (
            <NoddiButton onClick={onNextClicked} disabled={disableNextButton} endIcon='ArrowRight'>
              <Trans>Continue</Trans>
            </NoddiButton>
          )}
        </div>
      </div>
    </FixedBottomBarContainer>
  );
};

export default FixedBottomBar;
