import { Trans } from '@lingui/macro';
import { AddressProps, ServiceAreasDataFromCoordinate } from 'noddi-async/src/types';

import { getLongAddressName } from '../../../helpers';
import { useIsMobile, useScrollIntoView } from '../../../hooks';
import { getGenericErrorMessages } from '../../Elements';
import { ServiceAvailabilityInfo } from './ServiceAvailabilityInfo';
import { hasStreetNumber } from './addressUtils';

type SelectedAddressDetailsProps = {
  isServiceAreaError: boolean;
  serviceAreas: ServiceAreasDataFromCoordinate | undefined;
  hasServiceCategories: boolean;
  address: AddressProps | null;
  hideServiceInfoBox?: boolean;
  navigateToNextStepWhenAvailableService?: () => void;
};

const SelectedAddressDetails = ({
  isServiceAreaError,
  serviceAreas,
  hasServiceCategories,
  address,
  hideServiceInfoBox,
  navigateToNextStepWhenAvailableService
}: SelectedAddressDetailsProps) => {
  const commonStyles = 'p-4 mt-4 rounded-md text-secondary-black';
  const isMobile = useIsMobile();

  const containerRef = useScrollIntoView(!!address && isMobile); // Use the hook with a boolean flag

  if (!address) {
    return null;
  }

  const { genericFirewallText } = getGenericErrorMessages();

  if (isServiceAreaError) {
    return (
      <p ref={containerRef} className={`bg-signal-danger/40 ${commonStyles} `}>
        {genericFirewallText}
      </p>
    );
  }

  if (!hasStreetNumber(address)) {
    return (
      <p ref={containerRef} className={`bg-signal-warning50 ${commonStyles}`}>
        <Trans>Please enter an address with a street number so that we can check if we can come to you!</Trans>
      </p>
    );
  }

  const NoServiceOffered = () => (
    <p className={`bg-signal-warning50 ${commonStyles}`} ref={containerRef}>
      <Trans>
        Sorry, we do not currently deliver services to your address. Try another address, or try again at a later time
      </Trans>
    </p>
  );

  if (hideServiceInfoBox) {
    return hasServiceCategories ? null : <NoServiceOffered />;
  }

  return hasServiceCategories ? (
    <div style={{ marginTop: 24 }} ref={containerRef}>
      <ServiceAvailabilityInfo
        address={address ? getLongAddressName(address) : undefined}
        serviceCategories={serviceAreas?.serviceCategories ?? []}
        unavailableServiceCategories={serviceAreas?.unavailableServiceCategories ?? []}
        navigateToNextStepWhenAvailableService={navigateToNextStepWhenAvailableService}
      />
    </div>
  ) : (
    <NoServiceOffered />
  );
};

export { SelectedAddressDetails };
