import { t } from '@lingui/macro';
import { URLKeys, noddiAsync } from 'noddi-async';
import { ErrorPage, LoadingScreen } from 'noddi-ui';
import { Fragment, useState } from 'react';

import { useBookingContext } from '../../../../contexts/BookingContext';
import { useWheelStore } from '../../../../stores/WheelStore';
import BookingScreen from '../../BookingScreen';
import { useMembershipPrograms } from '../../hooks/useMembershipPrograms';
import { useStepValidator } from '../../hooks/useStepValidator';
import HasTireHotel from '../Addons/HasTireHotel';
import { useTireHotelCheck } from '../Addons/HasTireHotel/usetireHotelCheck';
import WheelStorageSelector from './WheelStorageSelector';
import useOnNextButtonClick from './useOnNextButtonClick';

const WheelStoragePageContent = () => {
  const { getWheelStorageItems } = useWheelStore();
  const { wheelStorageStepOk } = useStepValidator();
  const { salesItems } = useBookingContext();

  // prefetches membership programs
  useMembershipPrograms();
  const [isAddonsLoading, setIsAddonsLoading] = useState(false);

  const { navigateToNextStep } = useOnNextButtonClick();

  const {
    error: tireHotelCheckError,
    isTireHotelOptionsPending,
    shouldCheck: shouldCheckTireHotel,
    regNumbersWithTireHotel
  } = useTireHotelCheck();

  // this can only be one per car
  const wheelStorageSalesItems = getWheelStorageItems(salesItems);

  const { data, isPending, error } = noddiAsync.useGet({
    type: URLKeys.getWheelStorageSalesItemsOptions
  });

  async function onNextClicked() {
    setIsAddonsLoading(true);
    await navigateToNextStep();
    setIsAddonsLoading(false);
  }

  if (error) {
    return <ErrorPage />;
  }

  const enableNextButton = wheelStorageStepOk();

  return (
    <BookingScreen
      onNextClicked={onNextClicked}
      disableNextButton={!enableNextButton}
      title={t`Where are your wheels stored?`}
    >
      <HasTireHotel
        regNumbersWithTireHotel={regNumbersWithTireHotel}
        error={tireHotelCheckError}
        isTireHotelOptionsPending={isTireHotelOptionsPending}
        shouldCheck={shouldCheckTireHotel}
      >
        {isPending || isAddonsLoading ? (
          <LoadingScreen />
        ) : (
          <div className='flex flex-col gap-4'>
            {wheelStorageSalesItems.map((item) => (
              <Fragment key={item.licensePlateNumber}>
                <WheelStorageSelector licensePlateNumber={item.licensePlateNumber} wheelStorageOptions={data} />
              </Fragment>
            ))}
          </div>
        )}
      </HasTireHotel>
    </BookingScreen>
  );
};

export default WheelStoragePageContent;
