import { t } from '@lingui/macro';
import { Stack } from '@mui/material';
import { KeyValueRow, NoddiChip, NoddiIcon } from 'noddi-ui';

type Props = {
  slug?: string;
  isPaid?: boolean;
  isCancelled?: boolean;
  address?: string;
  getDateText: () => string;
};

export function MetaSection({ isCancelled, isPaid, slug, address, getDateText }: Props) {
  return (
    <div className='flex flex-col gap-2'>
      {isCancelled && (
        <div className='-mt-6 mb-2'>
          <NoddiChip label={t`Canceled`} variant='destructive' />
        </div>
      )}
      {isPaid && (
        <div className='-mt-6 mb-2'>
          <NoddiChip label={t`Paid`} variant='success' />
        </div>
      )}
      {address && (
        <KeyValueRow
          header={
            <Stack alignItems='center' direction='row' spacing={1}>
              <NoddiIcon name='LocationPin' size='medium' />

              <p>{address}</p>
            </Stack>
          }
          showDivider={false}
        />
      )}
      <KeyValueRow
        header={
          <Stack alignItems='center' direction='row' spacing={1}>
            <NoddiIcon name='Calendar' size='medium' />
            <p>{getDateText()}</p>
          </Stack>
        }
        showDivider={false}
      />
      {slug && (
        <KeyValueRow
          header={
            <Stack alignItems='center' direction='row' spacing={1}>
              <NoddiIcon size='medium' name='Magnifier' />
              <p>{t`Booking reference : ${slug}`}</p>
            </Stack>
          }
          showDivider={false}
        />
      )}
    </div>
  );
}
