import { t } from '@lingui/macro';
import { useAuthContext } from 'noddi-provider';
import { FixedBottomBarContainer, NoddiIcon, SMSLogin, UserRegistrationForm, getLongAddressName } from 'noddi-ui';
import { useEffect } from 'react';

import HelpScoutChat from '../../../../components/BookingFlow/HelpScoutChat';
import { useBookingContext } from '../../../../contexts/BookingContext';
import bookingTimeWindowToDisplayText from '../../../../helper/timeWindow';
import useActivateCoupon from '../../../../hooks/useActivateCoupon';
import tracking from '../../../../tracking';
import BookingScreen from '../../BookingScreen';
import { useMembershipPrograms } from '../../hooks/useMembershipPrograms';
import CommentBox from './CommentBox';
import ConfirmBooking from './ConfirmBooking';
import PriceSummary from './PriceSummary';

const Summary = () => {
  const { isLoggedIn } = useAuthContext();
  const { bookingInputData: inputData, updateBookingInputData, salesItems, bookingInputData } = useBookingContext();

  const { activateCouponForSalesItem, couponsFromApi: fetchedCoupons } = useActivateCoupon();

  const { data: membershipData } = useMembershipPrograms();

  const handleCommentChange = (newComment: string) => {
    updateBookingInputData({ comment: newComment });
  };

  useEffect(() => {
    if (isLoggedIn && fetchedCoupons?.length !== 0) {
      salesItems.forEach((salesItem) => {
        activateCouponForSalesItem({ salesItemId: salesItem.id, licensePlateNumber: salesItem.licensePlateNumber });
      });
    }
  }, [isLoggedIn, fetchedCoupons]);

  const timeWindowDisplay =
    (bookingInputData.time && bookingTimeWindowToDisplayText(bookingInputData.time)) ||
    t`Problems with the last time slot`;

  const bookingDataAddress = bookingInputData?.address;

  return (
    <BookingScreen
      title={t`Ready to confirm booking?`}
      hideNextButton
      CustomBottomBar={
        <FixedBottomBarContainer>
          <HelpScoutChat />
          <ConfirmBooking membershipPrograms={membershipData} />
        </FixedBottomBarContainer>
      }
    >
      <div className='flex flex-col gap-3'>
        {isLoggedIn && <CommentBox comment={inputData.comment} handleChange={handleCommentChange} />}

        {!isLoggedIn && (
          <SMSLogin
            userRegistrationForm={(phoneNumber) => (
              <UserRegistrationForm
                phoneNumber={phoneNumber}
                skipPasswordCreation
                trackUserIdentity={(user) => {
                  tracking.trackUserIdentity(user);
                }}
              />
            )}
          />
        )}

        <div className='flex flex-col gap-3 rounded-lg bg-primary-white px-4 py-3'>
          <div className=' flex gap-2'>
            <NoddiIcon name='Calendar' size='medium' />
            <p>{timeWindowDisplay}</p>
          </div>
          {bookingDataAddress && (
            <div className='flex gap-2'>
              <NoddiIcon name='LocationPin' size='medium' />
              <p>{getLongAddressName(bookingDataAddress)}</p>
            </div>
          )}
        </div>

        <PriceSummary membershipData={membershipData} />
      </div>
    </BookingScreen>
  );
};

export default Summary;
