import { Trans } from '@lingui/macro';
import { URLKeys, noddiAsync } from 'noddi-async';
import { UserDataProps } from 'noddi-async/src/types';
import { ErrorPage, LoadingScreen, useIsMobile } from 'noddi-ui';

import NoBookingsCard from '../../components/Home/NoUpcomingBookingsCard';
import HomeContentWrapper from '../../components/Layouts/HomeContentWrapper';
import TireHotel from '../TireHotel';
import Cars from './Cars';
import Coupons from './Coupons';
import HomePageHeader from './HomePageHeader';
import PreviousBookings from './Previousbookings';
import UpcomingBookings from './UpcomingBookings';

export const DEFAULT_NUMBER_OF_BOOKINGS_SHOWN = 1;

type HomeContentProps = {
  userGroupId: number;
  hasTireHotel: boolean;
  userData: UserDataProps;
};

const HomeContent = ({ userGroupId, hasTireHotel, userData }: HomeContentProps) => {
  const isMobile = useIsMobile();

  const {
    isPending: isNumberOfBookingsPending,
    data: numberOfBookings,
    error: numberOfBookingsError
  } = noddiAsync.useGet({
    type: URLKeys.getUserGroupNumberOfBookings,
    input: { userGroupId },
    queryConfig: { enabled: !!userGroupId }
  });

  if (numberOfBookingsError) {
    return <ErrorPage apiError={numberOfBookingsError} />;
  }

  if (isNumberOfBookingsPending) {
    return <LoadingScreen />;
  }

  const isFirstTimeCustomer = !numberOfBookings;

  return (
    <HomeContentWrapper widerScreen>
      <div className='mt-6 flex flex-col gap-6 text-primary-darkPurple md:mt-17 md:gap-17'>
        <HomePageHeader userData={userData} isFirstTimeCustomer={isFirstTimeCustomer} isMobile={isMobile} />

        <div className='grid grid-cols-1 gap-8 md:grid-cols-2'>
          {isFirstTimeCustomer && <NoBookingsCard />}

          <UpcomingBookings userGroupId={userGroupId} />
          {hasTireHotel && (
            <div className='flex flex-col gap-2 md:gap-4'>
              <h2 className='font-bold text-6 md:text-8'>
                <Trans>Tire hotel</Trans>
              </h2>
              <TireHotel />
            </div>
          )}
          <Cars userGroupId={userGroupId} />

          <PreviousBookings userGroupId={userGroupId} />
          <Coupons />
        </div>
      </div>
    </HomeContentWrapper>
  );
};

export default HomeContent;
