import { MembershipDiscountProps } from 'noddi-async/src/types';

const MembershipDiscount = (props: { discount: MembershipDiscountProps }) => {
  const { discount } = props;

  return (
    <div className='flex flex-col gap-3 rounded-lg bg-primary-white/80 p-4'>
      <h6>{discount.name}</h6>
      <p className='text-primary-black/50'>{discount.description}</p>
    </div>
  );
};

export default MembershipDiscount;
